import InstellingModel from '../../instelling.model';
import _state from '../../_global/bezoeklessen.state';
import { BezoeklesModel } from '../../bezoekles.model';

export default class MarkerFactory {

	public createInstellingMarker(instelling:InstellingModel) {
		const marker = new google.maps.Marker({
			position: {
				lat: instelling.lokatie.Latitude,
				lng: instelling.lokatie.Longitude
			},
			title: instelling.titel, // shows when you hover on marker
			label: {
				text: '' + instelling.bezoeklessen.length,
				color: 'white',
				fontSize: '14px',
				fontWeight: 'normal',
				fontFamily: '"Futura PT W01 Medium", Arial, "Helvetica Neue", Helvetica, sans-serif'
			},
			icon: {
				url: '/content/images/icons/bezoeklessen-kaart/marker_bolletje_instelling.png',
				scaledSize: new google.maps.Size(25, 25),
			},
		});
		marker.addListener('click', () => {
			_state.kaart.activeInstellingMarker$.next(marker);
		});

		marker.set('instelling', instelling);

		return marker;
	}

	public createBezoeklesMarker = (les:BezoeklesModel) => {
		const marker:google.maps.Marker = new google.maps.Marker({
			position: {
				lat: les.lokatie.lat(),
				lng: les.lokatie.lng()
			},
			title: les.titel, // shows when you hover on marker
			icon: this.getIconLink(les)
		});
		marker.set('les', les);
		// marker.addListener('click', () => this.highlightMarker(marker));
		return marker;
	};

	private getIconLink(les:BezoeklesModel):string {
		let iconLink = '/content/images/icons/bezoeklessen-kaart/marker_uitklas_lg_year_' + les.leerJaar + '.png';
		if (les.inSchool) {
			iconLink = '/content/images/icons/bezoeklessen-kaart/marker_inklas_lg_year_' + les.leerJaar + '.png';
		}
		return iconLink;
	}

	public createHighlightMarker = (bezoeklesMarker:google.maps.Marker):google.maps.Marker => {
		const kleurJaar12 = '#1b9cd3';
		const kleurJaar345 = '#ffba00';
		const kleurJaar678 = '#8ab000';

		const les:BezoeklesModel = bezoeklesMarker.get('les');
		let highlightKleur = kleurJaar678;
		if (les.leerJaar < 3) {
			highlightKleur = kleurJaar12;
		} else if (les.leerJaar < 6 && les.leerJaar > 2) {
			highlightKleur = kleurJaar345;
		}

		const highlightMarker = new google.maps.Marker({
			position: bezoeklesMarker.getPosition(),
			icon: {
				path: google.maps.SymbolPath.CIRCLE,
				fillOpacity: 0.5,
				fillColor: highlightKleur,
				strokeOpacity: 1.0,
				strokeColor: highlightKleur,
				strokeWeight: 3.0,
				scale: 20 //pixels,
			},
			animation: google.maps.Animation.DROP,
			zIndex: -1
		});

		return highlightMarker;
	};

	createLokatieMarker(lokatie:google.maps.LatLng):google.maps.Marker {
		return new google.maps.Marker({
			position: lokatie,
			// icon: '/content/images/views/gmaps/markers/marker_icon_home_lg.png',
			title: 'Ingestelde locatie voor afstand berekening', // shows when you hover on marker
		});
	};
}
