import _singletons from '../../_global/bezoeklessen.singletons';
import _state from '../../_global/bezoeklessen.state';
import InstellingenApiModel from '../../data/api-models/instellingen-api.model';
import _events from '../../_global/bezoeklessen.events';
import { PlaatsLocatieModel } from './plaats-locatie.model';
import KaartSettings from '../../kaart/kaart.settings';
import SearchService, { SearchResult } from './search.service';
import SearchUiInterface from './search-ui.interface';
import * as _ from 'lodash';
import InstellingModel from '../../instelling.model';

export default class SearchComponent implements SearchUiInterface {
	$input = $('.bezoeklessenMenu__instellingSearch');
	$inputLijst = $('.bezoeklessenLijst__wrapper .bezoeklessenMenu__instellingSearch')
	$resultsContainer = $('.autocompleteItem--container');
	$searchClearInstellingButton = $('.bezoeklessenMenu__clearInstellingInput');

	searchResult:SearchResult;

	searchService = new SearchService();

	constructor() {
		this.searchService.searchResult$.subscribe(async (searchResult) => {
			await this.handleNewSearchResult(searchResult);
		});
		this.setEvents();
	}

	async handleNewSearchResult(searchResult:SearchResult) {
		this.searchResult = searchResult;

		this.$resultsContainer.html('');

		const hasPlaatsResult = searchResult.plaatsen.length > 0;
		if (hasPlaatsResult) searchResult.plaatsen.forEach((p) => this.appendPlaatsResult(p));

		const hasInstellingResult = searchResult.instellingen.length > 0;
		if (hasInstellingResult){
			searchResult.instellingen.forEach((i) => this.appendInstellingResult(i));
		}

		if (searchResult.input === '') {
			this.clearInstellingSearch();
		} else if (hasPlaatsResult || hasInstellingResult) {
			this.openResults();
		} else {
			this.closeResults();
		}

	}

	openResults = () => this.$resultsContainer.addClass('is-open');
	closeResults = () => this.$resultsContainer.removeClass('is-open');

	handleSearchInputLostFocus = () => {
		// NOTE: Need to sleep a bit to process a potential click before hiding, at least 100ms
		setTimeout(() => {
			this.closeResults();
		}, 250);
	};

	handleSearchInputFocus() {
		if (_singletons.searchService.hasResults()) {
			this.openResults();
		}
	}

	appendPlaatsResult(plaats:PlaatsLocatieModel) {
		const $plaatsResult = $(`<div class="autocompleteItem autocompleteItem--plaats">
    				<div class="autocompleteItem__naam">
    					<i class="far fa-house" style="margin-right: 5px;"></i>
						${plaats.plaatsNiceName}
					</div>
    				<div class="autocompleteItem__woonplaats">${plaats.gemeente}</div>
				 </div>`).on('click', async () => await this.zoomInOnPlaats(plaats));

		this.$resultsContainer.append($plaatsResult);
	};

	async zoomInOnPlaats(plaats:PlaatsLocatieModel) {
		this.clearInstellingSearch();
		const instellingenInPlaats:InstellingModel[] =
			_.filter(_state.data.alleInstellingen, (i:InstellingModel) => i.woonplaats.toLowerCase() === plaats.plaats);
		if (instellingenInPlaats.length === 1) {
			_state.kaart.map.setCenter(instellingenInPlaats[0].latLng);
			_state.kaart.map.setZoom(KaartSettings.plaatsZoomDesktop);
		} else if (instellingenInPlaats.length > 1) {
			const bounds = new google.maps.LatLngBounds();
			instellingenInPlaats.forEach((i:InstellingModel) => bounds.extend(i.latLng));
			_state.kaart.map.fitBounds(bounds);
		} else {
			try {
				const latLng = await _singletons.afstandService.geocodePlaatsnaam(plaats.plaats + ', '+ plaats.gemeente);
				_state.kaart.map.setCenter({lat: latLng.lat(), lng: latLng.lng()});
				_state.kaart.map.setZoom(11);
			} catch (e) {
				console.error(e);
				_state.kaart.map.setZoom(KaartSettings.defaultZoom);
				_state.kaart.map.setCenter(KaartSettings.defaultCenter);
			}
		}
	}

	appendInstellingResult(instelling:InstellingenApiModel) {
		console.log('Appending instelling result!  voor instelling ', instelling);
		
		const $instellingResult = $(`<div class="autocompleteItem autocompleteItem--instelling">
											  <div class="autocompleteItem__naam">${instelling.titel}</div>
											  <div class="autocompleteItem__woonplaats">${instelling.woonplaats}</div>
										   </div>`).on('click', this.searchResultItemClick);
		this.$resultsContainer.append($instellingResult);
	}

	searchResultItemClick = (e) => {
		console.log(e);
		const searchInstellingName = this.searchService.getInstellingNameFromAutoCompleteItem($(e.target));

		_singletons.searchService.searchInstellingName = searchInstellingName;
		this.$input.val(searchInstellingName);

		this.startLookingAtInstellingState(searchInstellingName);
	};

	startLookingAtInstellingState(searchInstellingName:string) {
		this.clearInstellingSearch();
		const newFilters = _state.currentFilters;
		newFilters.instelling = searchInstellingName;

		_events.filtersChange$.next(newFilters);
	}

	setEvents = () => {
		this.$input.on('keyup', (e) =>
			this.searchService.searchInput$.next($(e.target).val() as string));
		this.$input.on('blur', this.handleSearchInputLostFocus);
		this.$input.on('focus', this.handleSearchInputFocus);
		this.$searchClearInstellingButton.on('click', this.clearInstellingSearch);
	};

	/** Method used by the Filter Status component to result search state */
	clearInstellingSearch() {
		this.$searchClearInstellingButton.removeClass('bezoeklessenMenu__clearInstellingInput--isShown');
		this.$input.val('');
		this.closeResults();
	}


}



