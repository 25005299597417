import FilterModel from './filter.model';

const FilterData:FilterModel = {
	"themaFilter": [
		{
			"leerJaar": "1",
			"naam": "Dit ben ik",
			"weergaveNaam": "1 Dit ben ik",
			"urlNaam": "dit-ben-ik"
		},
		{
			"leerJaar": "2",
			"naam": "De verzamelman",
			"weergaveNaam": "2 De verzamelman",
			"urlNaam": "de-verzamelman"
		},
		{
			"leerJaar": "3",
			"naam": "Mijn familie",
			"weergaveNaam": "3 Mijn familie",
			"urlNaam": "mijn-familie"
		},
		{
			"leerJaar": "4",
			"naam": "Museumassistent",
			"weergaveNaam": "4 Museumassistent",
			"urlNaam": "museumassistent"
		},
		{
			"leerJaar": "5",
			"naam": "Hoe woon jij?",
			"weergaveNaam": "5 Hoe woon jij?",
			"urlNaam": "hoe-woon-jij"
		},
		{
			"leerJaar": "6",
			"naam": "Je bent wat je eet",
			"weergaveNaam": "6 Je bent wat je eet",
			"urlNaam": "je-bent-wat-je-eet"
		},
		{
			"leerJaar": "6",
			"naam": "De kroniek van...",
			"weergaveNaam": "6 De kroniek van...",
			"urlNaam": "de-kroniek-van"
		},
		{
			"leerJaar": "7",
			"naam": "Mijn huis staat in...",
			"weergaveNaam": "7 Mijn huis staat in...",
			"urlNaam": "mijn-huis-staat-in"
		},
		{
			"leerJaar": "7",
			"naam": "Kunstschatten",
			"weergaveNaam": "7 Kunstschatten",
			"urlNaam": "kunstschatten"
		},
		{
			"leerJaar": "8",
			"naam": "Om niet te vergeten...",
			"weergaveNaam": "8 Om niet te vergeten...",
			"urlNaam": "om-niet-te-vergeten"
		},
		{
			"leerJaar": "8",
			"naam": "Voltooid verleden tijd",
			"weergaveNaam": "8 Voltooid verleden tijd",
			"urlNaam": "voltooid-verleden-tijd"
		}
	],
	"tijdvakFilter": [
		{
			"naam": "Jagers en Boeren - Tot 3000 v.Chr.",
			"urlNaam": "jagers-boeren"
		}, {
			"naam": "Grieken en Romeinen - 3000 v.Chr. tot 500 n.Chr.",
			"urlNaam": "grieken-romeinen"
		}, {
			"naam": "Monniken en Ridders - 500 tot 1000",
			"urlNaam": "monniken-ridders"
		}, {
			"naam": "Steden en Staten - 1000 tot 1500",
			"urlNaam": "steden-staten"
		}, {
			"naam": "Ontdekkers en Hervormers - 1500 tot 1600",
			"urlNaam": "ontdekkers-hervormers"
		}, {
			"naam": "Regenten en Vorsten -  1600 tot 1700",
			"urlNaam": "regenten-vorsten"
		}, {
			"naam": "Pruiken en Revoluties - 1700 tot 1800",
			"urlNaam": "pruiken-revoluties"
		}, {
			"naam": "Burgers en Stoommachines - 1800 tot 1900",
			"urlNaam": "burgers-stoommachines"
		}, {
			"naam": "Wereldoorlogen - 1900 tot 1950",
			"urlNaam": "wereldoorlogen"
		}, {
			"naam": "Televisie en Computers - 1950 tot heden",
			"urlNaam": "televisie-computers"
		},
	],
	"soortAanbodFilter": [
		{
			"naam": "Erfgoed",
			"urlNaam": "erfgoed"
		}, {
			"naam": "Kunst",
			"urlNaam": "kunst"
		}, {
			"naam": "Natuur",
			"urlNaam": "natuur"
		}, {
			"naam": "Techniek",
			"urlNaam": "techniek"
		}
	],
	"onderwerpFilter": [
		{
			"naam": "Archeologie",
			"urlNaam": "archeologie"
		},
		{
			"naam": "Beroepen",
			"urlNaam": "beroepen"
		},
		{
			"naam": "Bouwen",
			"urlNaam": "bouwen"
		},
		{
			"naam": "Dieren",
			"urlNaam": "dieren"
		},
		{
			"naam": "Duurzaamheid",
			"urlNaam": "duurzaamheid"
		},
		{
			"naam": "Media",
			"urlNaam": "media"
		},
		{
			"naam": "Religie",
			"urlNaam": "religie"
		},
		{
			"naam": "Strijd",
			"urlNaam": "strijd"
		},
		{
			"naam": "Vervoer",
			"urlNaam": "vervoer"
		},
		{
			"naam": "Water",
			"urlNaam": "water"
		},
		{
			"naam": "Wonen",
			"urlNaam": "wonen"
		}
	],
	"opSchoolFilter": [
		{
			"naam": "De klas uit",
			"urlNaam": "de-klas-uit"
		},
		{
			"naam": "Specialist in de klas",
			"urlNaam": "in-de-klas"
		}
	],
	"prijsFilter": [
		{
			"naam": "tot &euro;50",
			"urlNaam": "tot-50euro"
		},
		{
			"naam": "&euro;50 tot &euro;100",
			"urlNaam": "50eu-tot-100eu"
		},
		{
			"naam": "meer dan &euro;100",
			"urlNaam": "boven-100euro"
		},
	],
	coronaFilter: [
		{
			naam: "Neem contact op",
			urlNaam: "neem-contact-op"
		},
		{
			naam: "Is mogelijk",
			urlNaam: "is-mogelijk"
		},
		{
			naam: "Voorlopig niet",
			urlNaam: "voorlopig-niet"
		}
	]
};

export default FilterData;
