import _components from '../_global/bezoeklessen.components';
import Shepherd from 'shepherd.js';

const tourButton:{stoppen:Shepherd.Step.StepOptionsButton} = {
	stoppen: {
		text: '<i class="fas fa-times"></i>',
		action: () => {
			_components.tutorial.stop();
		},
		classes: 'tourStep__button--stop'
	},
};
export default tourButton;

