import _events from '../_global/bezoeklessen.events';

export default class MenuLijstComponent {
    $menu = $('.bezoeklessenMenuDesktop');
	$buttonNaarKaart = $('.bezoeklessenMenuDesktop__viewModeSelector--kaart');
	$buttonNaarKaartMobile = $('.bezoeklessenMenu__button--kaart');
	$buttonToggleSorteer = $('#bezoeklessenMenu__buttonSorteer');
	$buttonToggleFilters = $('.bezoeklessenMenu__button--filters');

	$sorteerButtons = $('.bezoeklessenMenu__sorteerButtons');
	$sorteerLeerjaar = $('.sorteerButton--leerjaar');
	$sorteerPrijs = $('.sorteerButton--prijs');
	$sorteerAfstand = $('.sorteerButton--afstand');

	$postcodeInput = $('.bezoeklessenMenu__postcodeInput--lijst');
	$postcodeInputMobile = $('.bezoeklessenMenuMobile__postcodeInput--lijst');
	$postcodeSubmit = $('.bezoeklessenMenu__postcodeSubmit--lijst');
	$postcodeSubmitMobile = $('.bezoeklessenMenuMobile__postcodeSubmit--lijst');

	constructor() {
		this.setEvents();
	}
	setNewPostcode = async () => {
		// todo - Must work via the FilterStatus oid observable, because also used in menu of lijst
		const postcode = this.$postcodeInput.val() as string;
		const sanitizedPostcode = postcode.replace(' ', '').toUpperCase();
		_events.postcodeChange$.next(sanitizedPostcode);
	};
	setNewPostcodeMobile = () => {
		const postcode = this.$postcodeInputMobile.val() as string;
		const sanitizedPostcode = postcode.replace(' ', '').toUpperCase();
		_events.postcodeChange$.next(sanitizedPostcode);
	}
	private setEvents() {
		this.$postcodeSubmit.on('click', () => this.setNewPostcode());
		this.$postcodeSubmitMobile.on('click', () => this.setNewPostcodeMobile());
		this.$sorteerLeerjaar.on('click', (e) => {
			this.$sorteerLeerjaar.addClass('sorteerButton--active');
			this.$sorteerPrijs.removeClass('sorteerButton--active');
			this.$sorteerAfstand.removeClass('sorteerButton--active');
			_events.sorteerOp$.next('leerjaar')
		});
		this.$sorteerPrijs.on('click', (e) => {
			this.$sorteerLeerjaar.removeClass('sorteerButton--active');
			this.$sorteerPrijs.addClass('sorteerButton--active');
			this.$sorteerAfstand.removeClass('sorteerButton--active');
			_events.sorteerOp$.next('prijs')
		});
		this.$sorteerAfstand.on('click', (e) => {
			this.$sorteerLeerjaar.removeClass('sorteerButton--active');
			this.$sorteerPrijs.removeClass('sorteerButton--active');
			this.$sorteerAfstand.addClass('sorteerButton--active');
			_events.sorteerOp$.next('afstand')
		});
		this.$buttonNaarKaart.on('click', () => {
		    _events.gotoLijstView$.next(false);
		});
		this.$buttonNaarKaartMobile.on('click', () => {
			_events.gotoLijstView$.next(false);
		});

		this.$buttonToggleSorteer.on('click', () => {
			console.log('Clicked on buttonToggleSorteer', );

			if (this.$sorteerButtons.hasClass('bezoeklessenMenu__sorteerButtons--isOpen')) {
				this.$sorteerButtons.removeClass('bezoeklessenMenu__sorteerButtons--isOpen');
			} else {
				this.$sorteerButtons.addClass('bezoeklessenMenu__sorteerButtons--isOpen');
			}
		});
		this.$buttonToggleFilters.on('click', () => {
		    $('.filters').addClass('filters--isOpen');
			$('.filters__toonResultaten').addClass('filters__toonResultaten--isOpen');

		})
		$('#filters__buttonClose').on('click', () => {
			$('.filters').removeClass('filters--isOpen');
			$('.filters__toonResultaten').removeClass('filters__toonResultaten--isOpen');
		});
		$('.filters__toonResultaten').on('click', () => {
			$('.filters').removeClass('filters--isOpen');
			$('.filters__toonResultaten').removeClass('filters__toonResultaten--isOpen');
		});

	}
}
