import { BezoeklesModel } from '../../bezoekles.model';
import BezoeklesOpsommingenFactory from '../../shared/bezoekles-opsommingen.factory';
import _components from '../../_global/bezoeklessen.components';
import _state from '../../_global/bezoeklessen.state';
import _singletons from '../../_global/bezoeklessen.singletons';

export default class OverlayMobileComponent {
    isOverlayOpen = false;

	$overlay = $('#bezoeklessenKaart__overlay');

	async render(les:BezoeklesModel) {
		if (!this.isOverlayOpen) {
			this.$overlay.addClass('bezoeklessenKaart__overlay--isOpen')
		}
		const overlayHtml =await this.renderHtml(les)
		this.$overlay.html(overlayHtml);
		$('.kaartMobileOverlay__close').on('click', () => this.close());



	}
	async renderHtml(les:BezoeklesModel) {
		const opsommingItems = BezoeklesOpsommingenFactory.createBezoeklesOpsomming(les);
		let hasAfstand = les.afstand !== 0;
		let autoAfstand = '';
		let fietsAfstand = '';
		if (hasAfstand) {
			_components.route.render(_state.afstandLokatie, les.lokatie, 'overlayDesktop');
			autoAfstand = await _singletons.afstandService
				.berekenAfstandMetDistanceMatrix(_state.afstandLokatie, les.lokatie, google.maps.TravelMode.DRIVING);
			fietsAfstand = await _singletons.afstandService
				.berekenAfstandMetDistanceMatrix(_state.afstandLokatie, les.lokatie, google.maps.TravelMode.BICYCLING);
		}
		return `
    <div class="kaartMobileOverlay kaartMobileOverlay--theme${les.leerJaar}">
        <a class="kaartMobileOverlay__title" href="${les.url}" target="_blank">
            <div class="kaartMobileOverlay__number kaartMobileOverlay__number--theme${les.leerJaar}">${les.leerJaar}</div>
            <div>${les.titel}</div>
        </a>
        <div class="kaartMobileOverlay__subtitle">${les.instelling}</div>
        <div class="coronaBericht ${les.coronaStatus.toLowerCase().replace(/\s/g, '-')}">
        	<div class="coronaBericht__text">${les.coronaBericht}</div>
			<div class="coronaBericht__icon"><img src="/Content/images/icons/icon-corona.svg" alt="Corona" /></div>
		</div>
        <a class="kaartMobileOverlay__image" href="${les.url}" target="_blank" >
            <div class="roundImage" style="background-image: url('${les.imageUrl}">
                &nbsp;
            </div>
        </a>
            ${opsommingItems}
        <div class="kaartMobileOverlay__beschrijving">
            ${les.beschrijving}
        </div>
        <div class="btn-group kaartMobileOverlay__routes ${hasAfstand ? 'kaartMobileOverlay__routes--active' : ''}">
            <button class="btn btn-default kaartMobileOverlay__button  kaartMobileOverlay__button--route kaartMobileOverlay__button--routeAuto">
                Toon route <i class="fas fa-car"></i> <span class="kaartMobileOverlay__routeAfstand--auto">${autoAfstand}</span>
            </button>
            <button class="btn btn-default kaartMobileOverlay__button  kaartMobileOverlay__button--route kaartMobileOverlay__button--routeFiets">
                <i class="fas fa-bicycle"></i> <span class="kaartMobileOverlay__routeAfstand--fiets">${fietsAfstand}</span>
            </button>
        </div>
        <a class="btn btn-default kaartMobileOverlay__button kaartMobileOverlay__button--details" href="${les.url}" target="_blank">Lees meer</a>

        <div class="kaartMobileOverlay__close"><i class="fas fa-times"></i></div>

    </div>`;
	}

	public close() {
		$('#bezoeklessenKaart__overlay').removeClass('bezoeklessenKaart__overlay--isOpen');
		// _state.kaart.activeInstellingMarker$.next(null); // Stack overflow?!

		// if (_singletons.kaart.isSingleBezoeklesInstellingOpen) {
		// 	_singletons.kaart.resetOpenedInstellingMarkerWithSingleBezoekles();
		// } else {
		// 	_singletons.kaart.removeCurrentHighlightMarker();
		// }

		// this.lastOpenedMarker = null;
		// this.isMobileOverlayOpen = false;
		//
		// _singletons.markerSpiderfier.collapseSpider();
		//
		// if (_singletons.bezoeklessen.isMobile) {
		//
		// } else {
		// 	_singletons.overlayDesktop.close();
		// }
	}
}
