import Shepherd from 'shepherd.js';
import _components from '../../_global/bezoeklessen.components';
import _events from '../../_global/bezoeklessen.events';
import tourButton from '../tutorial-button.object';




const html = `
<p>In de lijstweergave vind je alle bezoeklessen in een overzichtelijke lijst.</p>
<p>Gebruik de pijl rechtsonder om terug te gaan naar de bovenkant van de lijst.</p>
<p>Klik op het <i class="fas fa-map-marker-alt" aria-hidden="true"></i> icoontje om de bezoekles op de kaart te zien.</p>
`;

const lijstIntroStep:Shepherd.Step.StepOptions = {
	id: 'lijst-intro',
	title: 'Lijstweergave',
	text: html,
	attachTo: {
		element: '.bezoeklessenLijst__wrapper',
		on: 'top'
	},
	classes: 'bezoeklessenKaart__step lijst-intro',
	buttons: [
		tourButton.stoppen,

		{
			text: 'Vorige',
			action: () => {
				// menu-lijst
				_events.gotoLijstView$.next(false);
				_components.tutorial.tour.back();
			}
		},
		{
			text: 'Volgende',
			action: () => {
				// lijst-menu
				setTimeout(() => {
					window.scrollTo(0,0);
				}, 500);
				_components.tutorial.tour.next();
			}
		},
	]
}

export default lijstIntroStep;
