import Shepherd from 'shepherd.js';
import { createPopper, Instance } from '@popperjs/core';
import introStep from './steps/1-intro.step';
import instellingOmsStep from './steps/instelling-oms.step';
import bezoeklesInfo from './steps/bezoekles-info.step';
import menuAlgemeenStep from './steps/menu-algemeen.step';
import menuAfstandStep from './steps/menu-afstand.step';
import eindeLijstStep from './steps/einde-lijst.step';
import eenInstellingZoekenStep from './steps/een-instelling-zoeken.step';
import lijstIntroStep from './steps/lijst-intro.step';
import zoekenResultatenStep from './steps/zoeken-resultaten';
import filterIntroStep from './steps/filter-intro.step';
import filterDelenStep from './steps/filter-delen.step';
import lijstMenuStep from './steps/lijst-menu.step';
import routeBepalen from './steps/route.bepalen';
import _components from '../_global/bezoeklessen.components';

export default class TutorialComponent {

	tour:Shepherd.Tour;
	isAfstandSkipped = false;

	constructor() {

		window['createPopper'] = createPopper;

		this.tour = new Shepherd.Tour({
			defaultStepOptions: {
				classes: 'shadow-md bg-purple-dark',
				scrollTo: true
			},
			useModalOverlay: true
		});
		this.addSteps();
	}

	public start() {
		this.tour.start();
	}

	public stop() {
		this.tour.complete();
		_components.tutorialSuggestion.$menuTutorialKnop.removeClass('bezoeklessenMenu__button--active');
	}

	private addSteps() {
		// # Menu uitleg
		this.tour.addStep(menuAlgemeenStep);
		this.tour.addStep(introStep);
		// gebruik tutorial??
		// # Kaart weergave
		this.tour.addStep(instellingOmsStep);
		this.tour.addStep(bezoeklesInfo);
		// Afstand Steps
		this.tour.addStep(menuAfstandStep);
		// -- Continue if pressed Volgende, Else skippen naar menu zoeken
		this.tour.addStep(routeBepalen);
		// this.tour.addStep(afstandOverlayStep);
		// # Zoeken uitleg
		// this.tour.addStep(eenInstellingZoekenStep);
		// this.tour.addStep(zoekenResultatenStep);
		// # Filter uitleg
		this.tour.addStep(filterIntroStep);
		// this.tour.addStep(filterActiveStep);
		this.tour.addStep(filterDelenStep);
		// # Lijst weergave
		// this.tour.addStep(menuLijstStep);
		this.tour.addStep(lijstIntroStep);
		this.tour.addStep(lijstMenuStep);

		this.tour.addStep(eindeLijstStep);
	}
}
