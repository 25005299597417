import * as $ from 'jquery';
import { BehaviorSubject } from 'rxjs';

export default class BezoeklesDetailImagesService {
    $mainImageContainer:JQuery;
    $mainImage:JQuery;
    $bezoeklesDetailImageSharp:JQuery;
    $bezoeklesDetailImageBlur:JQuery;
    activeImageUrl:string;

    aspectSizeCutoff = 18;

    constructor() {
        const isBezoeklesPagina = true;
        if (isBezoeklesPagina) {
            this.$mainImage = $('.thumbnail-fullsize.img-responsive');
            this.$mainImageContainer = $(this.$mainImage.parent());
            this.$bezoeklesDetailImageSharp = $('.bezoeklesDetailImageSharp img');
            this.$bezoeklesDetailImageBlur = $('.bezoeklesDetailImageBlur');

            this.setMainImage(this.$mainImage);

            this.setEvents();
        }
    }

    public setMainImageAsBlurred = () => {
        this.$mainImageContainer.addClass('partial_bezoeklesdetail__image--isBlurred');
        // let imgUrl = '';
        setTimeout(() => {
            // imgUrl = this.$mainImage.attr('src');
            this.$bezoeklesDetailImageSharp.attr('src', this.activeImageUrl);
            this.$bezoeklesDetailImageBlur.attr('src', this.activeImageUrl);
        }, 10);
    }

    private setMainImageAsNormal = () => {
        this.$mainImageContainer.removeClass('partial_bezoeklesdetail__image--isBlurred');
    }


    private setMainImage($thumbnailImg) {
        this.activeImageUrl = $thumbnailImg.attr('src');

        const newAspectSize = Math.round(($thumbnailImg.width() / $thumbnailImg.height()) * 10) / 10;
        console.log('New aspect size: ', newAspectSize);
        if (newAspectSize < this.aspectSizeCutoff) {
            this.setMainImageAsBlurred();
        } else {
            this.setMainImageAsNormal();
        }
    }

    private setEvents() {
        $('.bezoeklessenDetailImages__thumbnail').on('click', (jEvent) => {

            const $thumbnailImg = $(jEvent.currentTarget).find('img');
            console.log('Clicked on thumbnial', $thumbnailImg);
            const imgUrlOfThumbnail = $thumbnailImg.attr('src');

            const isNotTheCurrentActiveMainImage = imgUrlOfThumbnail !== this.activeImageUrl;
            if (isNotTheCurrentActiveMainImage) {
                this.setMainImage($thumbnailImg);
            }
        });
    }
}
