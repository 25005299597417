import * as _ from 'lodash';
import { BezoeklesModel } from '../bezoekles.model';

export default class SorteerService {
	sorteer = (bezoeklessen:BezoeklesModel[], sorteerOp:string) => {
		switch(sorteerOp) {
			case 'afstand':
				return this.afstandOplopend(bezoeklessen);
			case 'prijs':
				return this.prijsOplopend(bezoeklessen);
			case 'leerjaar':
				return this.leerjaarOplopend(bezoeklessen);
		}
	};
	 prijsOplopend = (bezoeklessen) => _.sortBy(bezoeklessen,
		['prijs', 'leerJaar', 'afstand'],
		['asc', 'asc', 'asc']);
	 prijsAflopend = (bezoeklessen) => _.orderBy(bezoeklessen, ['prijs'], ['desc']);

	 afstandOplopend = (bezoeklessen) => _.sortBy(bezoeklessen,
		[ 'afstand', 'leerjaar', 'prijs'],
		[ 'asc', 'asc', 'asc']);
	 afstandAflopend = (bezoeklessen) => _.orderBy(bezoeklessen, ['afstand'], ['desc']);
	 leerjaarOplopend = (bezoeklessen:BezoeklesModel[]) => _.sortBy(bezoeklessen, ['leerJaar', 'prijs', 'afstand'],
		 ['asc', 'asc', 'asc'])
}
