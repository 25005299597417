import Shepherd from 'shepherd.js';
import _components from '../../_global/bezoeklessen.components';
import InstellingModel from '../../instelling.model';
import _state from '../../_global/bezoeklessen.state';
import { take } from 'rxjs/operators';
import tourButton from '../tutorial-button.object';

const html = `
<p>Op deze kaart staan alle beschikbare bezoeklessen. Je ziet twee type icoontjes:</p>

<ul>
	<li><img class="bezoeklessenKaart__legendaItem--icon" src="/content/images/icons/bezoeklessen-kaart/marker_bolletje_instelling.png"> staat voor een organisatie (zoals een museum). Het getal geeft het aantal bezoeklessen aan.</li>
	<li><img class="bezoeklessenKaart__legendaItem--icon" style="width: 34px;" src="/content/images/icons/bezoeklessen-kaart/m1_small.png"> betekent dat er meerdere organisaties in dit gebied zijn. Het getal geeft het aantal organisaties aan.</li>
</ul>
<p>Klik op het icoontje om bezoeklessen of organisaties te bekijken.</p>
`;

const introStep:Shepherd.Step.StepOptions = {
	id: '1-intro',
	title: 'Kaartweergave',
	text: html,
	attachTo: {
		element: '.bezoeklessen',
	},
	classes: 'bezoeklessenKaart__step tourStep__intro',
	buttons: [
		tourButton.stoppen,
		{
			text: 'Vorige',
			action: () => {
				_components.tutorial.tour.back();
			}
		},
		{
			text: 'Volgende',
			action: () => {
				introStepMarkerClick();
				_components.tutorial.tour.next();
			}
		}
	]
};

export const introStepMarkerClick = (openLes = false) => {
	_components.markers.activeInstellingenMarkers.forEach((m) => {
		const instelling:InstellingModel = m.get('instelling');
		if (instelling.titel === 'Paleis Het Loo') {
			_state.kaart.activeInstellingMarker$.pipe(take(1)).subscribe(() => {
				setTimeout(() => {
					// _state.kaart.activeBezoeklesOverlay$.next(les);
				}, 1500);
			});
			_state.kaart.activeInstellingMarker$.next(m);
		}
	});
};

export default introStep;
