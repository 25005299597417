import Shepherd from 'shepherd.js';
import _components from '../../_global/bezoeklessen.components';
import _events from '../../_global/bezoeklessen.events';
import { take } from 'rxjs/operators';
import tourButton from '../tutorial-button.object';

const html = `
<p>De lijst kan je sorteren op leerjaar en prijs. Verder heeft de zoekbalk dezelfde functies als in de kaartweergave.</p>
<p>Om weer terug te gaan naar de kaartweergave klik je op <i>kaart</i>.</p>
`;

const lijstMenuStep:Shepherd.Step.StepOptions = {
	id: 'lijst-menu',
	title: 'Menu',
	text: html,
	attachTo: {
		element: '.bezoeklessenMenuDesktop',
		on: 'bottom'
	},
	modalOverlayOpeningPadding: 10,
	classes: 'bezoeklessenKaart__step lijst-menu',
	buttons: [
		tourButton.stoppen,

		{
			text: 'Vorige',
			action: () => {
				// lijst-intro
				_components.tutorial.tour.back();
			}
		},
		{
			text: 'Volgende',
			action: () => {

				// einde
				_events.gotoLijstView$.pipe(take(1)).subscribe((isLijstView) => {
				    if (!isLijstView) {
						// _events.gotoLijstView$.next(false);
						// _components.tutorial.tour.removeStep();

					}
				});

				_components.tutorial.tour.next();
			}
		},
	]
}

export default lijstMenuStep;
