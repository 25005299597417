import FilterStatusModel from '../filter/filter-status/filter-status.model';
import InstellingenApiModel from '../data/api-models/instellingen-api.model';
import _state from '../_global/bezoeklessen.state';
import * as _ from 'lodash';

export default class LinkBuilderService {

	constructor() {
	}
	public setLink(status:FilterStatusModel, instellingen:InstellingenApiModel[]) {
		const url = this.buildLink(status, instellingen);
		window.history.pushState('page2', 'Bezoeklessen - Reizen in de Tijd', '/bezoeklessen' + url);
	}
	public buildLink(status:FilterStatusModel, instellingen:InstellingenApiModel[]) {
		let url = '';
		if (_state.afstandPostcode) {
			url += '/' + _state.afstandPostcode.toUpperCase();
		}
		if (status.corona !== 'alle-corona') {
			url += '/' + status.corona;
		}
		if (status.instelling !== '') {
			const instelling = _.find(instellingen, (i) => {
				return i.titel === status.instelling;
			});
			if (instelling) {
				url += '/instelling-' + instelling.urlTitel;
			}
		}
		if (status.afstand !== 'alle-afstanden') {
			url += '/' + status.afstand;
		}
		if (status.lokatie !== 'alle') {
			url += '/' + status.lokatie;
		}
		if (status.themaFilterGroep.length > 0) {
			status.themaFilterGroep.forEach((themaUrlNaam) => {
				url += '/thema-' + themaUrlNaam;
			})
		}
		if (status.tijdvakFilterGroep.length > 0) {
			status.tijdvakFilterGroep.forEach((tijdvakUrlNaam) => {
				url += '/tijdvak-' + tijdvakUrlNaam;
			})
		}
		if (status.soortAanbodFilterGroep.length > 0) {
			status.soortAanbodFilterGroep.forEach((soortAanbodUrlNaam) => {
				url += '/soort-' + soortAanbodUrlNaam;
			})
		}
		if (status.onderwerpFilterGroep.length > 0) {
			status.onderwerpFilterGroep.forEach((onderwerpUrlNaam) => {
				url += '/onderwerp-' + onderwerpUrlNaam;
			})
		}
		if (status.prijs !== 'alle-prijzen') {
			url += '/' + status.prijs;
		}
		return url;
	}
}
