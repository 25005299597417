import _state from '../../_global/bezoeklessen.state';

export default class KaartRouteComponent {
	directionsDisplay:google.maps.DirectionsRenderer;

	render = (from:google.maps.LatLng, to:google.maps.LatLng, classname:string) => {
		$(`.${classname}__button--route`).click((el) => {
			const isVoorAuto = $(el.currentTarget).hasClass(`${classname}__button--routeAuto`);
			const directionisRequest = {
				origin: from,
				destination: to,
				provideRouteAlternatives: false,
				travelMode: isVoorAuto ? google.maps.TravelMode.DRIVING : google.maps.TravelMode.BICYCLING,
				drivingOptions: {
					departureTime: new Date(),
				},
				unitSystem: google.maps.UnitSystem.METRIC
			};
			const directionsService = new google.maps.DirectionsService();
			this.directionsDisplay.setMap(_state.kaart.map);

			directionsService.route(directionisRequest, (result, status) => {
				if (status === google.maps.DirectionsStatus.OK) {
					this.directionsDisplay.setDirections(result);
					if (isVoorAuto) {
						$(`.${classname}__routeAfstand--auto`).html(` (${result.routes[0].legs[0].distance.text})`);
					} else {
						$(`.${classname}__routeAfstand--fiets`).html(` (${result.routes[0].legs[0].distance.text})`);
					}
				}
			});
		});
	}
}
