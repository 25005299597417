import Shepherd from 'shepherd.js';
import _state from '../../_global/bezoeklessen.state';
import _components from '../../_global/bezoeklessen.components';
import InstellingModel from '../../instelling.model';
import { take } from 'rxjs/operators';
import { introStepMarkerClick } from './1-intro.step';
import tourButton from '../tutorial-button.object';

const html = `
<p class="overlayDesktop--theme1">Klik op een icoontje voor de korte beschrijving van de bezoekles.</p>
<p>Klik op <i>Lees meer</i> voor meer informatie.</p>
<p>Klik op het vergrootglas (links onderin de kaart) om alle bezoeklessen weer in beeld te brengen. </p>
`;

const bezoeklesInfoStep:Shepherd.Step.StepOptions = {
	id: 'kaart-zoom',
	title: 'Informatie over bezoekles',
	text: html,
	attachTo: {
		element: '.bezoeklessenKaart',
		on: 'left'
	},
	popperOptions: {
		offset: [10, 8],
	},
	classes: 'bezoeklessenKaart__step kaart-zoom',
	buttons: [
		tourButton.stoppen,
		{
			text: 'Vorige',
			action: () => {
				// _state.kaart.activeInstellingMarker$.next(null);
				introStepMarkerClick();
				_components.tutorial.tour.back();
			}
		},
		{
			text: 'Volgende',
			action: () => {
				$('.resetZoomButton').trigger('click');
				_components.tutorial.tour.next();
				// $('input[data-urlnaam="tot-50euro"]').trigger('click');
				// setTimeout(() => {
				// }, 500);
			}
		},

	]
}

export default bezoeklesInfoStep;
