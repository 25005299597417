import Shepherd from 'shepherd.js';
import _components from '../../_global/bezoeklessen.components';
import _state from '../../_global/bezoeklessen.state';
import { introStepMarkerClick } from './1-intro.step';
import tourButton from '../tutorial-button.object';
import { zoekVoorbeeld } from './een-instelling-zoeken.step';

const html = `
<p>Open na het invullen van de postcode een bezoekles om de route te bekijken. Druk op <i>Toon route</i> om de route per fiets of auto te tonen. </p>
`;

const routeBepalen:Shepherd.Step.StepOptions = {
	id: 'afstand-postcode',
	title: 'Route bepalen',
	text: html,
	attachTo: {
		element: '.bezoeklessenMenu.bezoeklessenMenu--kaart',
		on: 'bottom'
	},
	classes: 'bezoeklessenKaart__step afstand-postcode',
	buttons: [
		tourButton.stoppen,
		{
			text: 'Vorige',
			action: () => {
				// menu-afstand
				if ($('.bezoeklessenMenu__postcodeSearch').hasClass('bezoeklessenMenu__postcodeSearch--isOpen')) {
					$('#bezoeklessenMenu__buttonPostcode').click();
				}
				_components.tutorial.tour.back();
			}
		},
		{
			text: 'Volgende',
			action: () => {
				// afstand-overlay
				console.log('Afstand postcode volgende met state: ', _state.afstandPostcode, ' / ', _state.afstandLokatie )
				// if (typeof _state.afstandPostcode === 'undefined' || _state.afstandPostcode === ''){
					// alert('Voer eerst een postcode in voordat je op volgende klikt.');
				// } else {
					// introStepMarkerClick();
					// setTimeout(() => {
					// 	$('div[title*="Dieren bij het Paleis"]').click()
					// }, 1500);
					if ($('.bezoeklessenMenu__postcodeSearch').hasClass('bezoeklessenMenu__postcodeSearch--isOpen')) {
						$('#bezoeklessenMenu__buttonPostcode').click();
					}
					_components.tutorial.tour.next();
					// setTimeout(() => {
					// 	zoekVoorbeeld();
					// }, 500);
				// }
			}
		},
	]
};

export default routeBepalen;
