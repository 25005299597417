import _state from '../../_global/bezoeklessen.state';
import _events from '../../_global/bezoeklessen.events';

export default class KaartLegendaComponent {
	private $legenda = $('#bezoeklessenKaart__legenda');

	public render = () => {
		const legendaEl = this.assignLegendaContent();
		_state.kaart.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(legendaEl);

		_events.kaartToggleLegenda$.subscribe((toggleLegenda) =>  toggleLegenda ?
			this.showLegenda() : this.hideLegenda());
	}
	public hideLegenda = () => {
		this.$legenda.addClass('hidden');
	}
	public showLegenda = () => {
		this.$legenda.removeClass('hidden');
	}
	private assignLegendaContent = ():HTMLElement => {
		const legendaItems = {
			instelling: {
				name: 'Instelling',
				icon: '/content/images/icons/bezoeklessen-kaart/marker_bolletje_instelling.png'
			},
			instellingen: {
				name: 'Meerdere instellingen',
				icon: '/content/images/icons/bezoeklessen-kaart/m1_small.png'
			},
			inSchool: {
				name: 'Specialist in de klas',
				icon: '/content/images/icons/bezoeklessen-kaart/marker_inklas_lg_year_1.png'
			},
			opBezoek: {
				name: 'De klas uit',
				icon: '/content/images/icons/bezoeklessen-kaart/marker_uitklas_lg_year_1.png'
			},
			home: {
				name: 'Locatie',
				icon: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png'
			}
		};

		for (const legendaItem in legendaItems) {
			const type = legendaItems[legendaItem];
			const name = type.name;
			const icon = type.icon;
			const div = document.createElement('div');
			div.innerHTML =
				`<div class="bezoeklessenKaart__legendaItem">
                    <div class="bezoeklessenKaart__legendaItem--iconWrapper">
                        <img class="bezoeklessenKaart__legendaItem--icon" src="${icon}">
                    </div>
                    <span class="bezoeklessenKaart__legendaItem--name">${name}</span>
                </div>`;
			this.$legenda.append(div);
		}
		return this.$legenda.get(0);
	};
}
