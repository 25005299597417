import { tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import FilterStatusModel from './filter/filter-status/filter-status.model';
import _state from './_global/bezoeklessen.state';
import _events from './_global/bezoeklessen.events';
import _singletons from './_global/bezoeklessen.singletons';
import _components from './_global/bezoeklessen.components';
import TutorialComponent from './tutorial/tutorial.component';

export default class BezoeklessenComponent {
	$bezoeklessen = $('#bezoeklessen');
	isKaartView = true;
	private isFirstLoad = true;

	public render(isPostcodeReloadRender = false) {
		if (this.isKaartView && !isPostcodeReloadRender) {
			_components.kaart.render(_state.filteredInstellingen);
		} else {
			_components.lijst.render(_state.filteredBezoeklessen);
		}
		_components.filter.render(_state.currentFilters);
		if (_state.afstandLokatie) {
			this.$bezoeklessen.addClass('bezoeklessen--hasPostcode ');
		} else {
			this.$bezoeklessen.removeClass('bezoeklessen--hasPostcode ');
		}
		_singletons.linkBuilder.setLink(_state.currentFilters, _state.data.apiData.instellingen);

		if (this.isFirstLoad) {
			this.onFirstLoad()
		}
	}

	public init = async () => {
		_components.kaart.initMap();

		const filterStatusFromLink = await _singletons.linkAnalyzer
			.getFilterStatusFromLink(_state.data.apiData.instellingen, window.location.pathname);
		_events.filtersChange$ = new BehaviorSubject<FilterStatusModel>(filterStatusFromLink);
		_events.postcodeChange$.subscribe((p) => this.postcodeChange(p));
		_events.filtersChange$
			.pipe(
				tap(this.resetKaartView),
				tap(this.setStateBasedOnFilters),
			)
			.subscribe(() => this.render());



		_events.gotoLijstView$.subscribe((gotoLijstView:boolean) => {
			if (gotoLijstView) {
				this.isKaartView = false;
				_components.lijst.initLijstView();
			} else {
				this.isKaartView = true;
				_components.kaart.initKaartView();
			}
			this.render()
		});


	};

	private onFirstLoad() {
		setTimeout(() => {
			_components.loading.hide();
			this.isFirstLoad = false;
			// _events.gotoLijstView$.next(true);

			if (_state.isFirstVisit()) {
				_components.tutorialSuggestion.render();
			}
		}, 500);

	}

	private async postcodeChange(newPostcode: string | null) {
			if (this.isFirstLoad && newPostcode === null) {
				return;
			} else if (newPostcode === null) {
				// remove markerLokatie
				_components.markers.activeLokatieMarker.setMap(null);
				// remove afstanden
				_state.data.alleBezoeklessen = _state.data.alleBezoeklessen.map((les) => {
					les.afstand = 0;
					les.lijstHtml = _components.lijstItem.renderHtml(les);
					return les;
				});
				_state.afstandLokatie = null;
				_state.afstandPostcode = '';
				if (_state.sorteerOp === 'afstand') {
					_state.sorteerOp = 'leerjaar';
					_components.menuLijst.$sorteerLeerjaar.addClass('sorteerButton--active');
					_components.menuLijst.$sorteerPrijs.removeClass('sorteerButton--active');
					_components.menuLijst.$sorteerAfstand.removeClass('sorteerButton--active');
				}
				return;
			} else if (this.isFirstLoad) {
				console.log('We got a first load with postcode', );

			};

			const postcodeLatLng = await _singletons.afstandService.geocodePostcode(newPostcode);
			_state.afstandLokatie = postcodeLatLng;
			_state.afstandPostcode = newPostcode;
			_state.data.alleBezoeklessen =
				await _singletons.bezoeklessenFactory.berekenRechteLijnAfstandBezoeklessen(postcodeLatLng, _state.data.alleBezoeklessen);

			_components.markers.renderLokatieMarker(postcodeLatLng);

			_components.overlay.render();
			this.render(true);
	}

	private setStateBasedOnFilters(filters:FilterStatusModel) {
		_state.currentFilters = filters;

		const filterResult = _singletons.filterService
			.filterInstellingen(filters, _state.data.alleBezoeklessen, _state.data.alleInstellingen);
		_state.filteredInstellingen = filterResult.instellingen;
		_state.filteredBezoeklessen = filterResult.bezoeklessen;
	}
	private resetKaartView() {
		_components.overlay.close();
		_components.kaart.resetZoom();
	}

}

