import BezoeklessenComponent from '../bezoeklessen.component';
import MarkersComponent from '../kaart/markers/markers.component';
import FilterComponent from '../filter/filter.component';
import KaartComponent from '../kaart/kaart.component';
import OverlayComponent from '../kaart/overlay/overlay.component';
import OverlayDesktopComponent from '../kaart/overlay/overlay-desktop.component';
import OmsComponent from '../kaart/markers/oms.component';
import LegendaComponent from '../kaart/legenda/legenda.component';
import GelderlandOutlineComponent from '../kaart/outline/gelderland-outline.component';
import MenuKaartComponent from '../menu/menu-kaart.component';
import LoadingComponent from '../loading.component';
import LijstComponent from '../lijst/lijst.component';
import KaartRouteComponent from '../kaart/route/kaart-route.component';
import SearchComponent from '../filter/search/search.component';
import MenuLijstComponent from '../menu/menu-lijst.component';
import LijstItemComponent from '../lijst/lijst-item.component';
import OverlayMobileComponent from '../kaart/overlay/overlay-mobile.component';
import TutorialComponent from '../tutorial/tutorial.component';
import TutorialSuggestionComponent from '../tutorial/tutorial-suggestion.component';

class BezoeklessenComponents {
	bezoeklessenComponent = new BezoeklessenComponent();
	filter = new FilterComponent();
	kaart:KaartComponent = new KaartComponent();
	gelderlandOutline:GelderlandOutlineComponent = new GelderlandOutlineComponent();
	route:KaartRouteComponent = new KaartRouteComponent();
	markers:MarkersComponent = new MarkersComponent();

	overlay:OverlayComponent = new OverlayComponent();
	overlayDesktop:OverlayDesktopComponent = new OverlayDesktopComponent();
	overlayMobile:OverlayMobileComponent = new OverlayMobileComponent();

	oms:OmsComponent = new OmsComponent();
	legenda:LegendaComponent = new LegendaComponent();
	loading:LoadingComponent = new LoadingComponent();

	lijst:LijstComponent = new LijstComponent();
	lijstItem:LijstItemComponent = new LijstItemComponent();

	menuKaart:MenuKaartComponent = new MenuKaartComponent();
	menuLijst:MenuLijstComponent = new MenuLijstComponent();

	search = new SearchComponent();

	tutorialSuggestion = new TutorialSuggestionComponent();
	tutorial = new TutorialComponent();
}
const _components = new BezoeklessenComponents();

export default _components;

window['_components'] = _components;

