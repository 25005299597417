import Fuse from 'fuse.js';
import InstellingenApiModel from '../../data/api-models/instellingen-api.model';
import { PlaatsLocatieModel } from './plaats-locatie.model';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map, skip } from 'rxjs/operators';
import _state from '../../_global/bezoeklessen.state';

export class SearchResult {
	input:string;
	plaatsen:PlaatsLocatieModel[];
	instellingen:InstellingenApiModel[];
}

class SearchService {
	searchInput$:BehaviorSubject<string> = new BehaviorSubject<string>('');
	searchResult$:Observable<SearchResult> = new Observable<SearchResult>();
	searchResults:InstellingenApiModel[] = [];
	searchInstellingName:string;
	hasResults = ():boolean => this.searchResults.length !== 0;

	constructor() {
		this.searchResult$ = this.searchInput$.pipe(
			skip(1),
			debounceTime(400),
			map((s:string) => {
				return {
					input: s,
					plaatsen: this.plaatsNaamMatches(s),
					instellingen: this.fuzzySearchResults(s, _state.data.apiData.instellingen)
				};
			})
		);
	}

	fuzzySearchResults = (textToSearch:string, instellingen:InstellingenApiModel[]):InstellingenApiModel[] => {
		const fuseOptions = {
			shouldSort: true,
			threshold: 0.3,
			location: 0,
			distance: 100,
			maxPatternLength: 32,
			minMatchCharLength: 2,
			keys: [
				'titel',
				'woonplaats'
			]
		};
		const fuse = new Fuse(instellingen, fuseOptions);
		this.searchResults = fuse.search(textToSearch).map((fuseResult) => {
		    return fuseResult.item;
		});

		return this.searchResults;
	};

	plaatsNaamMatches(plaatsnaam:string):PlaatsLocatieModel[] {
		const naamSanitized = plaatsnaam.toLowerCase();

		return _.filter(window['allePlaatsenArray'], (p) => p.plaats === naamSanitized);
	}

	getInstellingNameFromAutoCompleteItem($clickTarget:JQuery):string {
		const isWhitespaceClicked = $clickTarget.hasClass('autocompleteItem');
		const isInstellingNaamClicked = $clickTarget.hasClass('autocompleteItem__naam');
		const isInstellingWoonplaatsClicked = $clickTarget.hasClass('autocompleteItem__woonplaats');

		let searchInstellingName:string;

		if (isWhitespaceClicked) {
			searchInstellingName = $clickTarget.find('.autocompleteItem__naam').text();
		} else if (isInstellingNaamClicked) {
			searchInstellingName = $clickTarget.text();
		} else if (isInstellingWoonplaatsClicked) {
			searchInstellingName = $clickTarget.parent().find('.autocompleteItem__naam').text();
		}
		return searchInstellingName;
	}
}

export default SearchService;
