import FilterStatusModel from './filter-status/filter-status.model';
import _state from '../_global/bezoeklessen.state';
import { fromEvent } from 'rxjs';
import FilterCountsComponent from './filter-counts/filter-counts.component';
import FilterStatusComponent from './filter-status/filter-status.component';
import FilterShareComponent from './filter-share/filter-share.component';
import _events from '../_global/bezoeklessen.events';

export default class FilterComponent {

	public filterAmounts = new FilterCountsComponent();
	public filterStatus = new FilterStatusComponent();
	public filterShare = new FilterShareComponent();

	$filterGroepAfstand = $('.filterGroep--afstand');

	constructor() {
		this.setInputEvents();
		this.setExpandEvents();
	}

	render = (status:FilterStatusModel) => {
		this.filterAmounts.render();
		this.filterStatus.render(status);
		this.setFilterInputs(status);
		this.filterShare.render();

		if (_state.afstandLokatie) {
			this.$filterGroepAfstand.removeClass('hidden');
		} else {
			this.$filterGroepAfstand.addClass('hidden');
		}
	};

	private setFilterInputs(status:FilterStatusModel) {
		$(`.bezoeklessen-filter__thema-input`).prop('checked', false);
		status.themaFilterGroep.forEach((themaFilter) => {
			$(`.bezoeklessen-filter__thema-input[data-urlnaam=${themaFilter}]`)
				.prop('checked', true);
		});
		$(`.bezoeklessen-filter__tijdvak-input`).prop('checked', false);
		status.tijdvakFilterGroep.forEach((tijdvakFilter) => {
			$(`.bezoeklessen-filter__tijdvak-input[data-urlnaam=${tijdvakFilter}]`)
				.prop('checked', true);
		});
		$(`.bezoeklessen-filter__soort-aanbod-input`).prop('checked', false);
		status.soortAanbodFilterGroep.forEach((soortFilter) => {
			$(`.bezoeklessen-filter__soort-aanbod-input[data-urlnaam=${soortFilter}]`)
				.prop('checked', true);
		});
		$(`.bezoeklessen-filter__onderwerp-input`).prop('checked', false);
		status.onderwerpFilterGroep.forEach((onderwerpFilter) => {
			$(`.bezoeklessen-filter__onderwerp-input[data-urlnaam=${onderwerpFilter}]`)
				.prop('checked', true);
		});
		if (status.lokatie == 'alle') {
			$('input[name=lokatie]' + ':eq(2)').prop('checked', true);
		} else if (status.lokatie === 'in-de-klas') {
			$('input[name=lokatie]' + ':eq(1)').prop('checked', true);
		} else {
			$('input[name=lokatie]' + ':eq(0)').prop('checked', true);
		}

		if (status.prijs === 'alle-prijzen') {
			$('input[name=prijs]' + ':eq(3)').prop('checked', true);
		} else if (status.prijs === 'tot-50euro') {
			$('input[name=prijs]' + ':eq(0)').prop('checked', true);
		} else if (status.prijs === '50eu-tot-100eu') {
			$('input[name=prijs]' + ':eq(1)').prop('checked', true);
		} else if (status.prijs === 'boven-100euro') {
			$('input[name=prijs]' + ':eq(2)').prop('checked', true);
		}

		if (status.afstand === 'alle-afstanden') {
			$('input[name=afstand]' + ':eq(3)').prop('checked', true);
		} else if (status.afstand === 'tot-5km') {
			$('input[name=afstand]' + ':eq(0)').prop('checked', true);
		} else if (status.afstand === 'tot-15km') {
			$('input[name=afstand]' + ':eq(1)').prop('checked', true);
		} else if (status.afstand === 'tot-25km') {
			$('input[name=afstand]' + ':eq(2)').prop('checked', true);
		}
		if (status.corona === 'alle-corona') {
			$('input[name=corona]' + ':eq(3)').prop('checked', true);
		} else if (status.corona === 'neem-contact-op') {
			$('input[name=afstand]' + ':eq(0)').prop('checked', true);
		} else if (status.corona === 'is-mogelijk') {
			$('input[name=afstand]' + ':eq(1)').prop('checked', true);
		} else if (status.corona === 'voorlopig-niet') {
			$('input[name=afstand]' + ':eq(2)').prop('checked', true);
		}
	}

	private onInputChange = (e:any) => {
		const $currTarget = $(e.currentTarget);
		console.log('Input changed for: ', $currTarget);

		const inputType = $currTarget.attr('class')
			.replace('bezoeklessen-filter__', '')
			.replace('-input', '')
			.trim();
		const inputValue = $(e.currentTarget).data('urlnaam');

		const newFilters = _state.currentFilters;
		if (inputType === 'prijs') {
			newFilters.prijs = inputValue;
		} else if (inputType === 'thema') {
			newFilters.themaFilterGroep = this.getCheckedItems(inputType);
		} else if (inputType === 'lokatie') {
			newFilters.lokatie = inputValue;
		} else if (inputType === 'tijdvak') {
			newFilters.tijdvakFilterGroep = this.getCheckedItems(inputType);
		} else if (inputType === 'soort-aanbod') {
			newFilters.soortAanbodFilterGroep = this.getCheckedItems(inputType);
		} else if (inputType === 'onderwerp') {
			newFilters.onderwerpFilterGroep = this.getCheckedItems(inputType);
		} else if (inputType === 'afstand') {
			newFilters.afstand = inputValue;
		} else if (inputType === 'corona') {
			newFilters.corona = inputValue
		}

		console.log('New filters: ', newFilters);
		_events.filtersChange$.next(newFilters);
	};
	private getCheckedItems = (inputType:string) => {
		const checkedItems:string[] = [];
		$(`.bezoeklessen-filter__${inputType}-input:checked`).each((i, thema) => {
			checkedItems.push($(thema).data('urlnaam'));
		});
		return checkedItems;
	};

	private setInputEvents() {
		fromEvent($('.filters__content input'), 'click')
			.subscribe(this.onInputChange);
	}

	/** Sets the expand behavior for the filters */
	private setExpandEvents = () => {
		this.setExpandEventsFor('thema', 'thema\'s');
		this.setExpandEventsFor('tijdvak', 'tijdvakken');
		this.setExpandEventsFor('soort-aanbod', 'soorten aanbod');
		this.setExpandEventsFor('onderwerp', 'onderwerpen');
	};
	/** Helper method for expand behavior for a filter */
	private setExpandEventsFor = (className, meervoud) => {
		const $showAll = $('#bezoeklessen-filter__' + className + '-showall');
		const $filterExtra = $('.bezoeklessen-filter__' + className + '.filter-extra');
		$showAll.click(() => {
			if ($showAll.html() === '+ Alle ' + meervoud) {
				$filterExtra.css('display', 'block');
				$showAll.html('- Minder ' + meervoud);
			} else {
				$filterExtra.css('display', 'none');
				$showAll.html('+ Alle ' + meervoud);
			}
		});
	};


}
