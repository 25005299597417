import Shepherd from 'shepherd.js';
import _state from '../../_global/bezoeklessen.state';
import _components from '../../_global/bezoeklessen.components';
import { exampleWord, timeBetweenInput, zoekVoorbeeld } from './een-instelling-zoeken.step';
import FilterStatusModel from '../../filter/filter-status/filter-status.model';
import _events from '../../_global/bezoeklessen.events';
import tourButton from '../tutorial-button.object';

const html = `
<p>Gebruik filters om eenvoudig een geschikte bezoekles te vinden.</p>
<p>Let op: om prijzen te kunnen vergelijken is voor de berekening uitgegaan van 25 leerlingen.</p>
`;
export const setFilterStatusFilterStep = () => {
	const filterStatus = new FilterStatusModel();
	filterStatus.themaFilterGroep = ['de-verzamelman'];
	filterStatus.lokatie = 'de-klas-uit';
	_events.filtersChange$.next(filterStatus);
}

const filterIntroStep:Shepherd.Step.StepOptions = {
	id: 'filter-intro',
	title: 'Filters',
	text: html,
	attachTo: {
		element: '.filters',
		on: 'right'
	},
	classes: 'bezoeklessenKaart__step intro-filter',
	buttons: [
		tourButton.stoppen,
		{
			text: 'Vorige',
			action: () => {
				// zoeken-resultaten
				_components.tutorial.tour.back();
				// if (_components.search.$input.val() === '') {
				// 	zoekVoorbeeld();
				// 	setTimeout(() => {
				// 		_components.tutorial.tour.next();
				// 	}, timeBetweenInput * exampleWord.length);
				// } else {
				// _components.tutorial.tour.next();
				// }
			}
		},
		{
			text: 'Volgende',
			action: () => {
				// filter-active
				_components.tutorial.tour.next();
				setFilterStatusFilterStep();
			}
		},

	]
};

export default filterIntroStep;
