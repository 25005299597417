export default class AfstandService {
	// google.maps.geometry.spherical is not an API.
	public berekenRechteLijnAfstand = (google, van:google.maps.LatLng, naar:google.maps.LatLng) =>
		google.maps.geometry.spherical.computeDistanceBetween(van, naar);

	// -- google.maps.Geocoder API @ https://developers.google.com/maps/documentation/geocoding/start?hl=nl
	// -- google.maps.Geocoder costs $5 / 1,000 calls
	public async geocodePostcode(postcode:string):Promise<google.maps.LatLng> {
		return new Promise((resolve:(value:google.maps.LatLng) => void, reject) => {
			const geocoder = new google.maps.Geocoder();
			geocoder.geocode({address: postcode + ' The Netherlands'}, (results, status) => {
				if (status == google.maps.GeocoderStatus.OK) {
					resolve(results[0].geometry.location);
				} else {
					reject(status + ' - ' + postcode);
					alert('Foutieve postcode: ' + postcode);
				}
			});
		});
	}

	public async geocodePlaatsnaam(plaatsnaam:string):Promise<google.maps.LatLng> {
		return new Promise((resolve:(value:google.maps.LatLng) => void, reject) => {
			const geocoder = new google.maps.Geocoder();
			geocoder.geocode({address: plaatsnaam + ' The Netherlands'}, (results, status) => {
				if (status == google.maps.GeocoderStatus.OK) {
					resolve(results[0].geometry.location);
				} else {
					reject(status + ' - ' + plaatsnaam);
				}
			});
		});
	}

	// -- google.maps.DistanceMatrixService @ https://developers.google.com/maps/documentation/distance-matrix/start
	// -- google.maps.DistanceMatricService costs $5 / 1,000 calls. Or $10 for Advanced version.
	async berekenAfstandMetDistanceMatrix(van:google.maps.LatLng, naar:google.maps.LatLng, mode:google.maps.TravelMode) {
		return new Promise((resolve:(afstandText:string) => void, reject) => {
			const service = new google.maps.DistanceMatrixService();
			const distanceMatrixSettings:google.maps.DistanceMatrixRequest = {
				origins: [van],
				destinations: [naar],
				travelMode: mode,
			};
			service.getDistanceMatrix(distanceMatrixSettings, (results, status) => {
				if (status === google.maps.DistanceMatrixStatus.OK) {
					resolve(results.rows[0].elements[0].distance.text);
				} else {
					reject('');
				}
			});
		});
	}
}
