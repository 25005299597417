import KaartGmapsStyles from './kaart.gmaps-styles';

export default class KaartSettings {

	public static defaultCenter = {lat: 52.13, lng: 5.89};
	public static defaultZoom = 9;

	public static overlappingMarkerSpiderfier = {
		markersWontMove: true,
		markersWontHide: true,
		basicFormatEvents: true,
		keepSpiderfied: true,
		circleSpiralSwitchover: 15,
		spiralLengthStart: 50,
		circleFootSeparation: 50
	};
	public static markerClusterer = {
		gridSize: 30,
		maxZoom: 14,
		minimumClusterSize: 2,
		styles: KaartGmapsStyles.clusterStyles
	};
	// google.* might not be available, so you can only call the settings once you are sure it's loaded.
	public static mapSettings = ():google.maps.MapOptions => {
		return {
			center: KaartSettings.defaultCenter,
			zoom: KaartSettings.defaultZoom,
			mapTypeId: google.maps.MapTypeId.TERRAIN,
			streetViewControl: false,
			maxZoom: 17,
			mapTypeControl: false,
			fullscreenControl: false,
			clickableIcons: false,
			gestureHandling: 'greedy',
			styles: KaartGmapsStyles.mapStyling
		};
	};
	static plaatsZoomDesktop = 14;
}
