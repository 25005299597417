export default class ThemaApiModel {
	public id:number | string;
	public titel:string;
	public url:string;
	public urlTitel:string;
	public leerJaar:number;
	public korteBeschrijving:string;
	public rodeDraad:string;
	public kerndoelen:string;
	public canon:string;
	public tijdvakken:string;
	public lesdoelen:string;
}
