import _state from '../../_global/bezoeklessen.state';

export default class GelderlandOutlineComponent {

	private outlineOnMap:google.maps.KmlLayer;
	private limburgOutline:google.maps.KmlLayer;
  private zeelandOutline:google.maps.KmlLayer;
	public render() {
		// const serverUrl = 	`${window.location.protocol}//${window.location.hostname}/Content/`;
		const serverUrl = 	`https://www.reizenindetijd.nl/Content/`;

		// Google needs to be able to "see" the .kml, so a local version won't work.
		const gelderlandOmlijningSource = serverUrl + 'gelderland.kml';
		// todo make sure that live server can find the .kml file
		// const hostName = window.location.hostname;

		// const isOnlineServer = hostName.indexOf('192.168.178') === -1 && hostName !== 'localhost';
		// if (isOnlineServer) {
		//     omlijningSrc = `${window.location.protocol}//${window.location.hostname}/Content/gelderland.kml`;
		// }
		const omlijningOptions:google.maps.KmlLayerOptions = {
			url: gelderlandOmlijningSource,
			suppressInfoWindows: true,
			preserveViewport: true,
			map: _state.kaart.map
		};
		this.outlineOnMap = new google.maps.KmlLayer(omlijningOptions);

		const limburgOmlijningSource = serverUrl + 'limburg_omlijning.kml';
		const limburgOmlijning:google.maps.KmlLayerOptions = {
			url: limburgOmlijningSource,
			suppressInfoWindows: true,
			preserveViewport: true,
			map: _state.kaart.map
		};
		this.limburgOutline = new google.maps.KmlLayer(limburgOmlijning);

		const zeelandOmlijningSource = serverUrl + 'zeeland_omlijning.kml';
		const zeelandOmlijning:google.maps.KmlLayerOptions = {
      url: zeelandOmlijningSource,
      suppressInfoWindows: true,
      preserveViewport: true,
      map: _state.kaart.map
    }
    this.zeelandOutline = new google.maps.KmlLayer(zeelandOmlijning);
	}
}
