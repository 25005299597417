import _singletons from '../../_global/bezoeklessen.singletons';
import _state from '../../_global/bezoeklessen.state';

export default class FilterShareComponent {
	constructor() {
		this.setEvents();
	}

	render() {
		if (!($('.filters__statusHeader').html() === 'Geen filters actief')) {
			$('.filters__shareButtons').removeClass('hidden');
			// $('.sharethis-inline-share-buttons').removeClass('hidden');
		} else {
			$('.filters__shareButtons').addClass('hidden');
		}

	}

	private openShare(t) {
		var n, o, r, i;
		return _state.isMobile() ? window.open(t, '_blank') : t.indexOf('mailto:') > -1 ? document.location = t : (r = this.getWindowSize().height,
			i = this.getWindowSize().width,
			n = Math.min(600, .6 * r),
			o = Math.min(800, .8 * i),
			window.open(t, '', ['height=' + n, 'left=' + (i - o) / 2, 'top=' + (r - n) / 2, 'width=' + o, 'status=1', 'toolbar=0'].join(',')));
	}

	private getWindowSize() {
		var e, t, n, o;
		return e = document.body,
			t = document.documentElement,
			n = window.innerHeight,
			o = window.innerWidth,
			{
				height: n || t.clientHeight || e.clientHeight,
				width: o || t.clientWidth || e.clientWidth
			};
	}

	private removeEventListeners() {
		const $btns = $('.st-btn');
		$btns.each((i, btn) => {
			const $btn = $(btn);
			$btn.replaceWith($btn.clone());
		});
	}
	animateCSS(element, animationName, callback) {
		const node = document.querySelector(element)
		node.classList.add('animated', animationName)

		function handleAnimationEnd() {
			node.classList.remove('animated', animationName)
			node.removeEventListener('animationend', handleAnimationEnd)

			if (typeof callback === 'function') callback()
		}

		node.addEventListener('animationend', handleAnimationEnd)
	}
	private setEvents() {
		$('.filters__shareButtons--toggle').on('click', () => {
			console.log('Pressed shareButtons toggle', );
			$('#st-1').css('text-align', 'left');

			this.animateCSS('.filters__shareButtons--toggle', 'bounceOutLeft', () => {
				$('.filters__shareButtons--toggle').addClass('hidden');
				const $shareThisButtonsOld = $('.st-btn');
				$shareThisButtonsOld.css('display', 'inline-block');
				this.removeEventListeners();
				const $shareThisButtonsNew  = $('.st-btn');
				$shareThisButtonsNew.on('click', (e) => {
					const $btn = $(e.currentTarget);
					const network = $btn.data('network') as string;
					// const urlForNetwork = this.getUrlForNetwork(network);
					console.log('Clicked MY share button for: ', network);
					const filterUrlPart = _singletons.linkBuilder.buildLink(_state.currentFilters, _state.data.apiData.instellingen);
					const url = _singletons.filterShareService.getUrlForNetwork(network, filterUrlPart, _state.isMobile());
					console.log('Opening ', url);

					this.openShare(url);
				});
				$('.sharethis-inline-share-buttons').removeClass('hidden');
			})


			// https://twitter.com/intent/tweet?text=Bezoeklessen%20-%20Reizen%20in%20de%20Tijd&url=http%3A%2F%2Flocalhost%3A52581%2Fbezoeklessen

		});
	}

	x;
}
