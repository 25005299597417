declare global {
    interface Window { Cookies:any, $:any }
    interface JQuery { modal:any}
}

class CookieService {

    $buttonAccepteren:JQuery = $('#cookieMelding__accepteren');
    $buttonInstellingen:JQuery = $('#cookieMelding__instellingen');
    $buttonInstellingenFooter:JQuery = $('#cookieInstellingen__footerButton');
    $cookieMeldingContainer:JQuery = $('.cookieMelding--container');

    $instellingenCheckbox:JQuery = $('#cookieInstellingen__checkbox');
    $cookieInstelling:JQuery = $('#cookieInstellingen__instelling');

    $instellingenOpslaan:JQuery = $('#cookieInstellingen__opslaan');

    isConsentAccepted = ():boolean => window.Cookies.get('ga_consent') === 'accepted';

    constructor() {
        const ga_consent = window.Cookies.get('ga_consent');
        if (typeof ga_consent === 'undefined') {
            this.$cookieMeldingContainer.addClass('cookieMelding--weergeven');
        }
        this.setEvents();
    }
    showModal = () => {
        this.isConsentAccepted() ? this.$instellingenCheckbox.prop('checked', true) :
            this.$instellingenCheckbox.prop('checked', false);
        window.$('#cookieInstellingen').modal('show');
    };
    handleInstellingClick = (e) => {
        this.$instellingenOpslaan.removeClass('cookieInstellingen__opslaan--hidden');
        if (this.$instellingenCheckbox.prop('checked')) {

            this.$instellingenCheckbox.prop('checked', false);
        } else {
            this.$instellingenCheckbox.prop('checked', true);
        }
    };
    setEvents():void {
        this.$buttonAccepteren.on('click', () => {

            this.$cookieMeldingContainer.addClass('cookieMelding--accepted');
            window.Cookies.set('ga_consent', 'accepted', { expires: 365 });
        });
        this.$buttonInstellingen.on('click', () => {
            this.$cookieMeldingContainer.addClass('cookieMelding--accepted');
            this.showModal();
        });
        this.$buttonInstellingenFooter.on('click', this.showModal);
        this.$instellingenCheckbox.on('click', this.handleInstellingClick);
        this.$cookieInstelling.on('click', this.handleInstellingClick);
        this.$instellingenOpslaan.on('click', () => {
            const isConsentGiven = this.$instellingenCheckbox.prop('checked');
            if (isConsentGiven) {
                window.Cookies.set('ga_consent', 'accepted', { expires: 365 });
            } else {
                window.Cookies.set('ga_consent', 'denied', { expires: 365 });
                // remove google analytics cookies
                window.Cookies.remove('_ga');
                window.Cookies.remove('_gat');
                window.Cookies.remove('_gid');
            }
            location.reload();
        });
    }

	public setTutorialCookie(cookieValue:string):void {
		window.Cookies.set('ridt_tutorial', cookieValue, { expires: 365 });
	}

	public getTutorialCookie():string {
    	return window.Cookies.get('ridt_tutorial');
	}
}

export default CookieService;

