import BezoeklessenPaginaApiModel from './api-models/bezoeklessen-pagina-api.model';
import InstellingModel from '../instelling.model';
import { BezoeklesModel } from '../bezoekles.model';

export default class BezoeklessenDataModel {
	apiData:BezoeklessenPaginaApiModel;

	instellingenIndexedById:any;
	themasIndexedById:any;

	alleBezoeklessen:BezoeklesModel[];
	alleBezoeklessenZonderMarker:BezoeklesModel[];

	alleInstellingen:InstellingModel[];
}
