import FilterStatusModel from './filter-status.model';
import _components from '../../_global/bezoeklessen.components';
import _singletons from '../../_global/bezoeklessen.singletons';
import _state from '../../_global/bezoeklessen.state';
import _events from '../../_global/bezoeklessen.events';

export default class FilterStatusComponent {
	$filterStatusContainer = $('#bezoeklessen-filters-status');

	render = (status:FilterStatusModel) => {
		this.$filterStatusContainer.html('');
		if (!this.isFilterActive(status)) {
			$('<div class="filters__statusHeader filters__statusHeader--notActive">Geen filters actief</div>').appendTo(this.$filterStatusContainer);
			_components.menuKaart.renderFilterCount(0);
			return;
		}
		$('<div class="filters__statusHeader">Actieve filters</div>').appendTo(this.$filterStatusContainer);
		let $filters = $('<div class="filters__statusActive btn-group"></div>');
		if (_state.afstandLokatie) {
			$filters.append(`<button class="btn btn-primary btn-filter-status" data-type="postcode">Postcode ${_state.afstandPostcode} <li class="fa-times fa"></li></button>`)
		}

		$filters.append(this.createFilterHtml(status.themaFilterGroep, 'Thema', 'Thema\'s'));

		if (status.lokatie !== 'alle') {
			const lokatieTitle = status.lokatie === 'in-de-klas' ? 'In de klas' : 'De klas uit';
			$filters.append(`<button class="btn btn-primary btn-filter-status" data-type="Lokatie">${lokatieTitle} <li class="fa-times fa"></li></button>`);
		}
		$filters.append(this.createFilterHtml(status.tijdvakFilterGroep, 'Tijdvak', 'Tijdvakken'));
		$filters.append(this.createFilterHtml(status.soortAanbodFilterGroep, 'Soort aanbod', 'Soorten aanbod'));
		$filters.append(this.createFilterHtml(status.onderwerpFilterGroep, 'Onderwerp', 'Onderwerpen'));
		if (status.corona !== 'alle-corona') {
			$filters.append(`<button class="btn btn-primary btn-filter-status" data-type="Corona">${status.corona.replace(/-/g, ' ')} <li class="fa-times fa"></li></button>`);
		}
		if (status.prijs !== 'alle-prijzen') {
			let prijsTekst = 'prijs filter';
			if (status.prijs === 'tot-50euro') {
				prijsTekst = 'tot &euro;50';
			} else if (status.prijs === '50eu-tot-100eu'){
				prijsTekst = '&euro;50 tot &euro;100';
			} else if (status.prijs === 'boven-100euro') {
				prijsTekst = 'meer dan &euro;100';
			}
			$filters.append(`<button class="btn btn-primary btn-filter-status" data-type="Prijs">${prijsTekst} <li class="fa-times fa"></li></button>`);
		}

		if (status.instelling !== '') {
			$filters.append(`<button class="btn btn-primary btn-filter-status" data-type="Instelling">${status.instelling} <li class="fa-times fa"></li></button>`);
		}
		if (status.afstand !== 'alle-afstanden') {
			$filters.append(`<button class="btn btn-primary btn-filter-status" data-type="Afstand">${status.afstand} <li class="fa-times fa"></li></button>`);
		}

		this.$filterStatusContainer.append($filters);
		$('.btn-filter-status').on('click', this.handleFilterReset);
		const filterCount = _singletons.filterService.getFilterCount(status);

		_components.menuKaart.renderFilterCount(filterCount);
	};

	private isFilterActive = (status:FilterStatusModel) => {
		return status.lokatie !== 'alle' || status.themaFilterGroep.length !== 0 ||
			status.soortAanbodFilterGroep.length !== 0 || status.tijdvakFilterGroep.length !== 0 ||
			status.onderwerpFilterGroep.length !== 0 || status.prijs !== 'alle-prijzen' ||
			status.instelling !== '' || status.afstand !== 'alle-afstanden' || _state.afstandLokatie || status.corona !== 'alle-corona';
	};
	private createFilterHtml = (filterArray, enkelNaam, mvNaam) => {
		if (filterArray.length === 0) {
			return '';
		}
		const filterName = `${filterArray.length} ${filterArray.length === 1 ? enkelNaam : mvNaam}`;
		const $filterStatusButton = $(
			`<button data-type="${enkelNaam}" class="btn btn-primary btn-filter-status">${filterName} <li class="fa-times fa"></li>
		</button>`)
		return $filterStatusButton;
	};
	private handleFilterReset = (e) => {
		const enkelNaam = $(e.currentTarget).data('type');
		const newFilters = _state.currentFilters;
		const filterName = enkelNaam.toLowerCase();
		if (filterName === 'thema') {
			$('.bezoeklessen-filter__thema-input:checked').prop('checked', false);
			newFilters.themaFilterGroep = [];
		} else if (filterName === 'lokatie') {
			$('input[name=lokatie]' + ':eq(2)').prop('checked', true);
			newFilters.lokatie = 'alle';
		} else if (filterName === 'prijs') {
			$('input[name=prijs]' + ':eq(3)').prop('checked', true);
			newFilters.prijs = 'alle-prijzen';
		} else if (filterName === 'instelling') {
			newFilters.instelling = '';
		} else if (filterName === 'tijdvak') {
			$('.bezoeklessen-filter__tijdvak-input:checked').prop('checked', false);
			newFilters.tijdvakFilterGroep = [];
		} else if (filterName === 'soort aanbod') {
			$('.bezoeklessen-filter__soort-aanbod-input:checked').prop('checked', false);
			newFilters.soortAanbodFilterGroep = [];
		} else if (filterName === 'onderwerp') {
			$('.bezoeklessen-filter__onderwerp-input:checked').prop('checked', false);
			newFilters.onderwerpFilterGroep = [];
		} else if (filterName === 'afstand') {
			$('input[name=afstand]' + ':eq(3)').prop('checked', true);
			newFilters.afstand = 'alle-afstanden';
		} else if (filterName === 'postcode') {
			_events.postcodeChange$.next(null);
		} else if (filterName === 'corona') {
			$('input[name=corona]' + ':eq(4)').prop('checked', true);
			newFilters.corona = 'alle-corona';
		}

		_events.filtersChange$.next(newFilters);
	}
}
