import { BezoeklesModel } from '../bezoekles.model';
import _components from '../_global/bezoeklessen.components';
import _singletons from '../_global/bezoeklessen.singletons';
import _events from '../_global/bezoeklessen.events';
import { skip, take } from 'rxjs/operators';
import _state from '../_global/bezoeklessen.state';
import * as $ from 'jquery';
import InstellingModel from '../instelling.model';

export default class LijstComponent {
	$wrapper = $(`#bezoeklessenLijst__wrapper`);
	$lijstContent = $(`#bezoeklessenLijst__content`);
	$naarBovenButton = $('#bezoeklessenLijst__naarBoven');
	isNaarBovenButtonZichtbaar = false;

	constructor() {
		_events.sorteerOp$.pipe(skip(1)).subscribe((sorteerOp) => {
			console.log('new sorteer op! ', sorteerOp);
			_state.sorteerOp = sorteerOp;
			this.render(_state.filteredBezoeklessen);
		});
		this.naarBovenButton();
	}

	render(bezoeklessen:BezoeklesModel[]) {
		this.$lijstContent.html(' ');
		const sortedList = _singletons.sorteerService.sorteer(bezoeklessen, _state.sorteerOp);
		sortedList.forEach((les:BezoeklesModel) => {
			const $lijstItem = $(les.lijstHtml);
			$lijstItem.find('.lijstBezoeklesItem__button--toonOpKaart').on('click', (e) => {
			    _events.gotoLijstView$.pipe(take(1)).subscribe(() => {
					_components.markers.activeInstellingenMarkers.forEach((m) => {
						const instelling:InstellingModel = m.get('instelling');
						if (instelling.titel === les.instelling) {
							_state.kaart.activeInstellingMarker$.pipe(take(1)).subscribe(() => {
								setTimeout(() => {
									_state.kaart.activeBezoeklesOverlay$.next(les);
								}, 1500)
							})
							_state.kaart.activeInstellingMarker$.next(m);
						}
					});
			    })
				_events.gotoLijstView$.next(false);
			})
			this.$lijstContent.append($lijstItem);
		});
	}

	naarBovenButton = () => {
		const $window = $(window);
		$window.on('scroll',() => {
			const isInZichtbareRange = $window.scrollTop() > 600;
			if (isInZichtbareRange && !this.isNaarBovenButtonZichtbaar) {
				this.toonNaarBovenButton();
			} else if (!isInZichtbareRange && this.isNaarBovenButtonZichtbaar) {
				this.verbergNaarBovenButton();
			}
		});
		this.$wrapper.on('scroll',() => {
			const isInZichtbareRange = this.$wrapper.scrollTop() > 600;
			if (isInZichtbareRange && !this.isNaarBovenButtonZichtbaar) {
				this.toonNaarBovenButton();
			} else if (!isInZichtbareRange && this.isNaarBovenButtonZichtbaar) {
				this.verbergNaarBovenButton();
			}
		} )
		this.$naarBovenButton.on('click', () => {
			$('body, html').animate({scrollTop: 0}, "slow");
			this.$wrapper.animate({scrollTop: 0}, "slow");
		});
	};
	private toonNaarBovenButton = () => {
		this.$naarBovenButton.addClass('bezoeklessenLijst__naarBoven--isOpen');
		this.isNaarBovenButtonZichtbaar = true;
	};
	public verbergNaarBovenButton = () => {
		this.$naarBovenButton.removeClass('bezoeklessenLijst__naarBoven--isOpen');
		this.isNaarBovenButtonZichtbaar = false;
	};
	initLijstView() {
		const $body = $('body');
		$body.addClass('p-bezoeklessen--lijst');
		$body.removeClass('p-bezoeklessen--kaart');
		_components.kaart.$wrapper.addClass('hidden');
		this.$wrapper.removeClass('hidden');
		// const isMobile = true;
		// if (!isMobile) {
		// 	this..mapSelectButton.removeClass('btn-highlight');
		// 	this..listSelectButton.addClass('btn-highlight');
		// 	this..filtersDetachableContent
		// 		.detach()
		// 		.appendTo(this..listFiltersContent);
		// }
	}
}
