import _components from './_global/bezoeklessen.components';

export default class LoadingComponent {
	$loadingIndicator = $(`#bezoeklessen-loading-indicator`);
    render = () => {
    	const $bezoeklessenContainer = $('#bezoeklessen');
		const groteWijzer = document.createElementNS("http://www.w3.org/2000/svg", "svg");
		groteWijzer.setAttribute ("width", "10" );
		groteWijzer.setAttribute ("height", "100" );
		let $groteWijzer = $(groteWijzer);
		$groteWijzer.addClass('groteWijzer');
		$groteWijzer.addClass('loading__wijzer');

		const kleinWijzer = document.createElementNS("http://www.w3.org/2000/svg", "svg");
		kleinWijzer.setAttribute ("width", "10" );
		kleinWijzer.setAttribute ("height", "50" );
		let $kleineWijzer = $(kleinWijzer);
		$kleineWijzer.addClass('kleineWijzer');
		$kleineWijzer.addClass('loading__wijzer');

		$bezoeklessenContainer.prepend(`<div class="loading"></div>`)
		$('.loading').append($kleineWijzer);
		$('.loading').append($groteWijzer);

		$kleineWijzer.wrap('<div class="loading__wijzer--wrap loading__wijzer--klein"></div>');
		$groteWijzer.wrap('<div class="loading__wijzer--wrap loading__wijzer--grote"></div>');
	}

	hide() {
		this.$loadingIndicator.addClass('startFade');
		setTimeout(() => {
		    this.$loadingIndicator.addClass('hidden');
		}, 1000)

	}
}
