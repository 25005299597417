import Shepherd from 'shepherd.js';
import _state from '../../_global/bezoeklessen.state';
import _components from '../../_global/bezoeklessen.components';
import tourButton from '../tutorial-button.object';

const html = `
<p>Klik een organisatie aan om te zien welke bezoeklessen aangeboden worden.</p>
<ul>
	<li><img class="bezoeklessenKaart__legendaItem--icon" src="/content/images/icons/bezoeklessen-kaart/marker_inklas_lg_year_6.png"> is een bezoekles <b>in de klas</b></li>
	<li><img class="bezoeklessenKaart__legendaItem--icon" src="/content/images/icons/bezoeklessen-kaart/marker_uitklas_lg_year_3.png"> is een bezoekles <b>op locatie</b></li>
</ul>
<p>
<b class="kleur-blauw">Blauw:</b> leerjaar 1 & 2<br>
<b class="kleur-geel">Geel:</b> leerjaar 3 t/m 5<br>
<b class="kleur-groen">Groen:</b> leerjaar 6 t/m 8<br>
</p>
`;

const instellingOmsStep:Shepherd.Step.StepOptions = {
	id: 'instelling-oms',
	title: 'Overzicht bezoeklessen',
	text: html,
	attachTo: {
		element: '.bezoeklessenKaart',
		on: 'left'
	},
	popperOptions: {
		offset: [10, 8],
	},
	classes: 'bezoeklessenKaart__step instelling-oms',
	buttons: [
		tourButton.stoppen,
		{
			text: 'Vorige',
			action: () => {
				$('.resetZoomButton').trigger('click');
				_components.tutorial.tour.back();
			}
		},
		{
			text: 'Volgende',
			action: () => {
				$('div[title*="Dieren bij het Paleis"]').click()

				_components.tutorial.tour.next();


				// $('input[data-urlnaam="tot-50euro"]').trigger('click');
				// setTimeout(() => {
				// }, 500);
			}
		},

	]
}

export default instellingOmsStep;
