import * as _ from 'lodash';
import InstellingModel from '../instelling.model';
import FilterStatusModel from './filter-status/filter-status.model';
import { BezoeklesModel } from '../bezoekles.model';
import InstellingenFactory from '../data/instellingen.factory';
import _state from '../_global/bezoeklessen.state';

export default class FilterService {

	constructor(private instellingenFactory:InstellingenFactory) {
	}

	filterInstellingen(status:FilterStatusModel, bezoeklessen:BezoeklesModel[], instellingen:InstellingModel[]):{
		instellingen:InstellingModel[], bezoeklessen:BezoeklesModel[]} {

		const filteredBezoeklessen = this.filterBezoeklessen(status, bezoeklessen);
		const instellingenMetBezoeklessen = instellingen.map((instelling) => {
			return this.instellingenFactory.createInstelling(filteredBezoeklessen, instelling);
		});

		return {
			instellingen: instellingenMetBezoeklessen.filter((instelling) => instelling.bezoeklessen.length > 0),
			bezoeklessen: filteredBezoeklessen
		};
	}

	isCorona = (les:BezoeklesModel, coronaFilter:string) => {
		if (coronaFilter === 'alle-corona') {
			return true;
		} else if (coronaFilter === 'is-mogelijk' && les.coronaStatus === 'Is mogelijk') {
			return true;
		} else if (coronaFilter === 'voorlopig-niet' && les.coronaStatus === 'Voorlopig niet') {
			return true;
		} else if (coronaFilter === 'neem-contact-op' && les.coronaStatus === 'Neem contact op') {
			return true;
		}
		return false;
	}

	isBetaalbaar = (les:BezoeklesModel, prijsFilter:string) => {
		if (prijsFilter === 'alle-prijzen') {
			return true;
		} else if (prijsFilter === 'tot-50euro' && les.prijs < 50) {
			return true;
		} else if (prijsFilter === '50eu-tot-100eu' && les.prijs <= 100 && les.prijs >= 50) {
			return true;
		} else if (prijsFilter === 'boven-100euro' && les.prijs > 100) {
			return true;
		}
		return false;
	}
	isBinnenAfstand = (les:BezoeklesModel, afstandFilter:string) => {
		if (afstandFilter === 'alle-afstanden') {
			return true;
		} else if (afstandFilter === 'tot-5km' && les.afstand < 5) {
			return true;
		} else if (afstandFilter === 'tot-15km' && les.afstand < 15) {
			return true;
		} else if (afstandFilter === 'tot-25km' && les.afstand < 25) {
			return true;
		}
		return false;
	}
	isInFilterGroep = (filterGroep, filterNaam) => filterGroep.length === 0 ? true :
		_.includes(filterGroep, filterNaam);
	isValueOfArrayInFilterGroep = (filterGroep:string[], filterArrayNaam:string[]) => {
		if (filterGroep.length === 0) {
			return true;

		}
		let isIncluded = false;
		filterArrayNaam.forEach((filterNaam) => {
			if (_.includes(filterGroep, filterNaam.toLowerCase())) {
				isIncluded = true;
			}
		});
		return isIncluded;
	};
	isTijdvakInFilterGroep = (filterGroep, tijdvakkenNiceNames) => {
		if (filterGroep.length === 0) {
			return true;

		}
		let isIncluded = false;
		tijdvakkenNiceNames.forEach((tijdvakNiceName) => {
			// Tijdvakken zijn complexer geworden, maar enigzins voorspelbaar in vorm <TIJDVAK_NAAM_OUD> - <TIJDVAK_PERIODE>
			// So we can split on ' - ' and select the first part, which is oud.. then use the old logic to make it like the url,
			// which is then used for filtering.
			if (_.includes(filterGroep, tijdvakNiceName.split(' - ')[0]
				.toLowerCase().replace(' en ', '-'))) {
				isIncluded = true;
			}
		});
		return isIncluded;
	};

	public filterBezoeklessen = (status:FilterStatusModel, bezoeklessen:BezoeklesModel[]) => {
		const gefilterdeBezoeklessen = _.filter(bezoeklessen, (les) => {
			if (!les.soortenAanbod) {

			}
			const isInSoortAanbodSelection = this.isValueOfArrayInFilterGroep(status.soortAanbodFilterGroep, les.soortenAanbod);
			const isInThemaSelection = this.isInFilterGroep(status.themaFilterGroep, les.themaUrlNaam);

			const isInTijdvakSelection = this.isTijdvakInFilterGroep(status.tijdvakFilterGroep, les.tijdvakken);
			const isInOnderwerpSelection = this.isValueOfArrayInFilterGroep(status.onderwerpFilterGroep, les.onderwerpen);
			const isCorrecteLokatie = status.lokatie === 'alle' ||
				(les.inSchool && status.lokatie === 'in-de-klas') ||
				(!les.inSchool && status.lokatie === 'de-klas-uit');
			const isCorrecteInstelling = status.instelling === '' ? true : status.instelling === les.instelling;
			let isBinnenAfstand:boolean;
			if (status.afstand === 'alle-afstanden') {
				isBinnenAfstand = true;
			} else {
				isBinnenAfstand = this.isBinnenAfstand(les, status.afstand);
				// isBinnenAfstand = les.afstand < this.getAfstandValue();
			}
			const isBetaalbaar = this.isBetaalbaar(les, status.prijs);
			const isCorona = this.isCorona(les, status.corona);
			const bezoeklesVoldoetAanAlleEisen = isBetaalbaar && isInSoortAanbodSelection
				&& isInThemaSelection && isInTijdvakSelection && isInOnderwerpSelection
				&& isCorrecteLokatie && isCorrecteInstelling && isBinnenAfstand && isCorona;

			return bezoeklesVoldoetAanAlleEisen;
		});

		return gefilterdeBezoeklessen;
	}
	public filterBezoeklessenRadios = (status:FilterStatusModel, bezoeklessen:BezoeklesModel[]) => {
		return _.filter(bezoeklessen, (les) => {

			const isInSoortAanbodSelection = this.isValueOfArrayInFilterGroep(status.soortAanbodFilterGroep, les.soortenAanbod);
			const isInThemaSelection = this.isInFilterGroep(status.themaFilterGroep, les.themaUrlNaam);
			const isInTijdvakSelection = this.isTijdvakInFilterGroep(status.tijdvakFilterGroep, les.tijdvakken);
			const isInOnderwerpSelection = this.isValueOfArrayInFilterGroep(status.onderwerpFilterGroep, les.onderwerpen);

			const isCorrecteInstelling = status.instelling === '' ? true : status.instelling === les.instelling;

			return isInSoortAanbodSelection && isInThemaSelection && isInTijdvakSelection && isInOnderwerpSelection && isCorrecteInstelling
		});
	}

	public getFilterCount(status:FilterStatusModel) {
		let totalFilters = 0;
		if (status.themaFilterGroep.length > 0) totalFilters++;
		if (status.lokatie !== 'alle') totalFilters++;
		if (status.tijdvakFilterGroep.length > 0) totalFilters++;
		if (status.soortAanbodFilterGroep.length > 0) totalFilters++;
		if (status.onderwerpFilterGroep.length > 0) totalFilters++;
		if (status.instelling !== '') totalFilters++;
		if (status.prijs !== 'alle-prijzen') totalFilters++;
		if (status.afstand !== 'alle-afstanden') totalFilters++;
		if (_state.afstandLokatie) totalFilters++;
		if (status.corona !== 'alle-corona') totalFilters++;

		return totalFilters;
	};
}
