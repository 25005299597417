import * as _ from 'lodash';

import _state from '../../_global/bezoeklessen.state';
import { BezoeklesModel } from '../../bezoekles.model';
import _singletons from '../../_global/bezoeklessen.singletons';
import FilterDescriptionRadio from '../filter-description-radio.model';


export default class FilterCountsComponent {
	filteredBezoeklessen:BezoeklesModel[];
	filteredBezoeklessenRadios:BezoeklesModel[];

	render = () => {
		this.filteredBezoeklessen = _state.filteredBezoeklessen;
		this.filteredBezoeklessenRadios = _singletons.filterService.filterBezoeklessenRadios(_state.currentFilters, _state.data.alleBezoeklessen);
		this.renderFilterAmountRadio(_singletons.filterCountService.getCoronaFilter());
		this.renderFilterAmountCheckbox('thema', 'themaUrlNaam');
		this.renderFilterAmountRadio(_singletons.filterCountService.getLokatieFilter());
		this.renderFilterAmountCheckbox('tijdvak', 'tijdvakken');
		this.renderFilterAmountCheckbox('soort-aanbod', 'soortenAanbod');
		this.renderFilterAmountCheckbox('onderwerp', 'onderwerpen');
		this.renderFilterAmountRadio(_singletons.filterCountService.getPrijsFilter());
		if (_state.afstandLokatie) {
			this.renderFilterAmountRadio(_singletons.filterCountService.getAfstandFilter());
		}
	};

	private renderFilterAmountCheckbox = (dataKey:string, modelPropertyName) => {
		$(`.${_state.appName}-filter__${dataKey}-count`).each((i, el) => {
			const filterName = $(el).attr('value');
			let count = 0;
			this.filteredBezoeklessen.forEach((les) => {
				if (Array.isArray(les[modelPropertyName])) {

					les[modelPropertyName].forEach((tijdvak) => {
						let isIncluded = tijdvak === filterName;

						if (modelPropertyName === 'tijdvakken') {
						}
							if (isIncluded) {
								count++;
							}
						}
					);
				} else if (les[modelPropertyName] === filterName) {
					count++;
				}
			});
			$(el).html(`(${count})`);
		});
	};

	private renderFilterAmountRadio = (filterDescriptionRadio:FilterDescriptionRadio) => {
		$(`.bezoeklessen-filter__${filterDescriptionRadio.slug}-count`).each((i, el) => {
			const type = $(el).data('urlnaam');
			let count = this.filteredBezoeklessenRadios.length;
			const filter = _.find(filterDescriptionRadio.filters, (f) => f.urlName === type);
			if (filter) {
				count = _.filter(this.filteredBezoeklessenRadios, filter.predicate).length;
			} else {
				/** If it can't find the filter, it will default to all the bezoeklessen. Which is fine for "Alle" filters. */
				// console.error('Couldn\'t find urlname for filter: ', type, `. From ${FilterDescriptionRadio.name} instance: `, coronaFilter);
			}
			$(el).html(`(${count})`);
		});
	};
}
