import App from './App';
import AppSettings from "./AppSettings";

const settings = new AppSettings();

const main: App = new App({
    exposeModel: true
});

$(document).ready(main.init);


console.log('Ahh we working now? wrong entry file ups', );
