import BezoeklesApiModel from './bezoekles-api.model';
import ThemaApiModel from './thema-api.model';
import InstellingApiModel from './instellingen-api.model';


export default class BezoeklessenPaginaApiModel {
	bezoeklessen:BezoeklesApiModel[];
	themas:ThemaApiModel[];
	instellingen:InstellingApiModel[];
}
