export default class FilterStatusModel {
	themaFilterGroep:any[] = [];
	lokatie = 'alle';
	tijdvakFilterGroep:any[] = [];
	soortAanbodFilterGroep:any[] = [];
	onderwerpFilterGroep:any[] = [];
	instelling = '';
	prijs = 'alle-prijzen';
	afstand = 'alle-afstanden';
	corona = 'alle-corona';
}
