import BezoeklesOpsommingenFactory from '../shared/bezoekles-opsommingen.factory';
import { BezoeklesModel } from '../bezoekles.model';

export default class LijstItemComponent {

	renderHtml = (les:BezoeklesModel) => {
		const opsommingItemsHorizontaal = BezoeklesOpsommingenFactory.createBezoeklesOpsomming(les, true);
		const opsommingItemsVerticaal = BezoeklesOpsommingenFactory.createBezoeklesOpsomming(les);

		return `
<div class="lijstBezoeklesItem lijstBezoeklesItem--theme${les.leerJaar}">
    <a class="lijstBezoeklesItem__title" href="${les.url}" target="_blank">
        <div class="lijstBezoeklesItem__number lijstBezoeklesItem__number--theme${les.leerJaar}">${les.leerJaar}</div>
        <div>${les.titel}</div>
    </a>
    <div class="coronaBericht ${les.coronaStatus.toLowerCase().replace(/\s/g, '-')}">
        <div class="coronaBericht__text">${les.coronaBericht}</div>
		<div class="coronaBericht__icon"><img src="/Content/images/icons/icon-corona.svg" alt="Corona" /></div>
	</div>
    <div class="lijstBezoeklesItem__button lijstBezoeklesItem__button--toonOpKaart lijstBezoeklesItem__button--theme${les.leerJaar} lijstBezoeklesItem__toonOpKaart"
        data-lesurl="${les.url}" ><i class="fas fa-map-marker-alt"></i>
    </div>
    <div class="lijstBezoeklesItem__content lijstBezoeklesItem__content--desktop">
        <a class="lijstBezoeklesItem__image" href="${les.url}" target="_blank" >
            <div class="roundImage" style="background-image: url('${les.imageUrl}"></div>
        </a>
        <div class="lijstBezoeklesItem__beschrijving--container">
            <div class="lijstBezoeklesItem__beschrijving">
                ${les.beschrijving}
            </div>
            ${opsommingItemsHorizontaal}
        </div>
    </div>
    <div class="lijstBezoeklesItem__content lijstBezoeklesItem__content--mobile">
        <div class="lijstBezoeklesItem__imageOpsomming">
            <a class="lijstBezoeklesItem__image" href="${les.url}" target="_blank" >
                <div class="roundImage" style="background-image: url('${les.imageUrl}"></div>
            </a>
            ${opsommingItemsVerticaal}
        </div>
        <div class="lijstBezoeklesItem__beschrijving">
            ${les.beschrijving}
        </div>
    </div>
    <div class="lijstBezoeklesItem__separator"></div>
</div>
`
	}
}
