import Shepherd from 'shepherd.js';
import _components from '../../_global/bezoeklessen.components';
import { introStepMarkerClick } from './1-intro.step';
import tourButton from '../tutorial-button.object';

const html = `
<p>In de bovenste balk kun je zoeken naar een organisatie of plaats. </p>
<p>Via het menu vind je de volgende functies:</p>
<p>
	<div><b>Filters:</b> geeft aan hoeveel filters er zijn aangeklikt en opent het filtermenu (op mobiel).</div>
	<div><b>Afstand:</b> berekent de afstand tot bezoeklessen.</div>
	<div><b>Legenda:</b> toont de legenda.</div>
	<div><b>Lijst:</b> toont lijstweergave van de bezoeklessen.</div>
</p>
`;

const menuAlgemeenStep:Shepherd.Step.StepOptions = {
	id: 'menu-algemeen',
	title: 'Menu',
	text: html,
	attachTo: {
		element: '.bezoeklessenMenu.bezoeklessenMenu--kaart',
		on: 'bottom'
	},
	classes: 'bezoeklessenKaart__step menu-algemeen',
	buttons: [
		tourButton.stoppen,
		{
			text: 'Vorige',
			action: () => {
				// _state.kaart.activeInstellingMarker$.next(null);
				introStepMarkerClick();
				setTimeout(() => {
					$('div[title*="Dieren bij het Paleis"]').click()
				}, 1500);
				_components.tutorial.tour.back();
			}
		},

		{
			text: 'Volgende',
			action: () => {
				_components.tutorial.tour.next();
				_components.tutorial.isAfstandSkipped = false;
				// $('input[data-urlnaam="tot-50euro"]').trigger('click');
				// setTimeout(() => {
				// }, 500);
			}
		},

	]
}

export default menuAlgemeenStep;
