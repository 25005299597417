import { BehaviorSubject } from 'rxjs';
import FilterStatusModel from '../filter/filter-status/filter-status.model';

class BezoeklessenEvents {
	kaartToggleLegenda$ = new BehaviorSubject<boolean>(false);
	postcodeChange$ = new BehaviorSubject<string | null>(null);
	filtersChange$:BehaviorSubject<FilterStatusModel>;

	gotoLijstView$ = new BehaviorSubject<boolean>(false);

	sorteerOp$ = new BehaviorSubject<string>('leerjaar');
}

const _events = new BezoeklessenEvents();

export default _events;

window['_events'] = _events;
