export default class FilterShareService {
	private title = 'Reizen in de Tijd - Bezoeklessen';
	private url =  'https://reizenindetijd.nl/bezoeklessen';

	getUrlForNetwork(network:string, filterUrlPart:string, isMobile = false) {
		switch (network) {
			case 'email':
				return this.getUrlForEmail(filterUrlPart);
			case 'facebook':
				return this.getUrlForFacebook(filterUrlPart);
			case 'twitter':
				return this.getUrlForTwitter(filterUrlPart);
			case 'whatsapp':
				return this.getUrlForWhatsapp(filterUrlPart, isMobile);
		}
	}

	getUrlForEmail(filterUrlPart:string) {
		const url = `mailto:?Subject=${this.title}&Body=${this.url}${filterUrlPart}`;
		return encodeURI(url);
	}

	getUrlForFacebook(filterUrlPart:string) {
		const encodedUrlPart = encodeURIComponent(filterUrlPart);
		return `https://www.facebook.com/sharer.php?t=${encodeURIComponent(this.title)}&u=${encodeURIComponent(this.url)}` +
			encodedUrlPart;
	}

	getUrlForTwitter(filterUrlPart:string) {
		const encodedUrlPart = encodeURIComponent(filterUrlPart);
		return `https://twitter.com/intent/tweet?text=${encodeURIComponent(this.title)}&url=${encodeURIComponent(this.url)}` +
			encodedUrlPart;
	}

	getUrlForWhatsapp(filterUrlPart:string, isMobile:boolean) {
		const encodedUrlPart = encodeURIComponent(filterUrlPart);
		if (isMobile) {
			return `whatsapp://send?${encodeURIComponent(this.url)}${encodedUrlPart}`;
		}
		return `https://web.whatsapp.com/send?${encodeURIComponent(this.url)}${encodedUrlPart}`;
	}
}
