/* tslint:disable:max-line-length */
import Menu from './Menu/Menu';
import Parallax from './Parallax/Parallax';
import ParallaxMobile from './Parallax/ParallaxMobile';

import LeesMeerService from './Services/LeesMeerService';
import AppSettings from './AppSettings';
import CookieService from './Services/CookieService';
import BezoeklesDetailImagesService from './Services/bezoekles-detail-images.service';
import BezoeklessenApp from './apps/bezoeklessen/bezoeklessen.app';
import _singletons from './apps/bezoeklessen/_global/bezoeklessen.singletons';

declare global {
    interface Window {
        bezoekLessenPageModel:any;
        bezoekLessen:any;
        app:App;
    }
}

class ExpandableInfo {
    constructor() {
        const $expandingItem = $('.expandableInfo__item');
        $expandingItem.click((e) => {
            // todo if target is link, don't close the item

            const $item = $(e.currentTarget);

            if ($(window).width() >= 576 && !$item.hasClass('expandableInfo__item--leesMeer')) {
                return;
            }
            const $content = $item.children('.expandableInfo__itemContent');
            const $icon = $item.find('.expandableInfo__itemIcon');
            if ($content.hasClass('expandableInfo__itemContent--isOpen')) {
                $content.css('max-height', 0);
                $item.removeClass('expandableInfo__item--isOpen');
                $item.addClass('expandableInfo__item--isClosed');

                $content.removeClass('expandableInfo__itemContent--isOpen');
                $icon.addClass('expandableInfo__itemIcon--down');
                $icon.addClass(['fa-caret-down']);
                $icon.removeClass('expandableInfo__itemIcon--up');
                $icon.removeClass([ 'fa-caret-up']);
            } else {
                $content.css('max-height', $content.prop('scrollHeight'));
                $content.addClass('expandableInfo__itemContent--isOpen');
                $item.removeClass('expandableInfo__item--isClosed');
                $item.addClass('expandableInfo__item--isOpen');
                $icon.removeClass('expandableInfo__itemIcon--down');
                $icon.removeClass([ 'fa-caret-down']);
                $icon.addClass('expandableInfo__itemIcon--up');
                $icon.addClass([ 'fa-caret-up']);
            }
        });
    }
}

const isFlexboxEnabled = () => {
    const f = 'flex';
    const fw = `-webkit-${f}`;
    const el = document.createElement('b');

    try {
        el.style.display = fw;
        el.style.display = f;
        return !!(el.style.display === f || el.style.display === fw);
    } catch (err) {
        return false;
    }
};

class App {
    debugMode = false;
    settings:AppSettings;

    menu:Menu;
    parallax:Parallax;
    parallaxMobile:ParallaxMobile;
    bezoekLessen:BezoeklessenApp;
    bezoekLessenPageModel:object | undefined;

    expandableInfo:ExpandableInfo;

    cookieService:CookieService;

    bezoeklesDetailImagesService:BezoeklesDetailImagesService;

    constructor(settings:AppSettings) {
        this.settings = settings;
        if (!isFlexboxEnabled()) {
            $('body').append(`
            <div class="flexboxMessage" style="z-index: 10000000; background-color:rgba(0,0,0, .85); position:fixed;top:0;left:0;height:100%;width:100%;text-align:center;vertical-align:middle; color: white; font-size: 30px; padding: 30px;">
                Deze browser ondersteund helaas niet de technologie die nodig is om deze site goed weer te geven. Voor optimaal gebruik wordt <a href="www.chrome.">Google Chrome</a> aangeraden.
                Voor een volledige lijst van ondersteunde browsers en versies kunt u kijken op <a href="https://caniuse.com/#feat=flexbox">caniuse.com</a>. Excuses voor het ongemak.<br>
                <a id="flexboxMessage__close">Klik om door te gaan</a>
            </div>`);
            $('#flexboxMessage__close').on('click', () => {
                $('.flexboxMessage').css('display', 'none');
            });
        }

    }

    init = () => {
        const enableDebugMode = () => {
            if (this.debugMode) return;
            console.log('Enabling debug mode', this);

            this.debugMode = true;
            window.app = this;

            $('body').prepend(`
            <div class="debugger">
               <div class="debugger__width">${$(window).width()}px x ${$(window).height()}px</div>
                <div class="debugger__viewportUp--container">
                    <div class="debugger__viewportUp debugger__viewportUp--phone">p</div>
                    <div class="debugger__viewportUp debugger__viewportUp--tabletPortrait">tp</div>
                    <div class="debugger__viewportUp debugger__viewportUp--tabletLandscape">tl</div>
                    <div class="debugger__viewportUp debugger__viewportUp--laptop">l</div>
                    <div class="debugger__viewportUp debugger__viewportUp--desktop">d</div>
                </div>
                <div class="debugger__viewportOnly--container">
                    <div class="debugger__viewportOnly debugger__viewportOnly--phone"></div>
                    <div class="debugger__viewportOnly debugger__viewportOnly--tabletPortrait"></div>
                    <div class="debugger__viewportOnly debugger__viewportOnly--tabletLandscape"></div>
                    <div class="debugger__viewportOnly debugger__viewportOnly--laptop"></div>
                    <div class="debugger__viewportOnly debugger__viewportOnly--desktop"></div>
                </div>
                <div class="debugger__browserOnly--container">
                    <div class="debugger__browserOnly debugger__browserOnly--ie11">ie11</div>
                    <div class="debugger__browserOnly debugger__browserOnly--edge">edge</div>
                    <div class="debugger__browserOnly debugger__browserOnly--ff">ff</div>
                </div>
            </div>
            `);

            $(window).on('resize', () => {
                $('.debugger__width').html(`${$(window).width()}px x ${$(window).height()}px`);
            });
        };
        const host = window.location.host;
        if (host.indexOf('localhost') !== -1 || host.indexOf('82.73.86.97') !== -1 || host.indexOf('h.figidon.com') !== -1) {
            enableDebugMode();
            $('.debugger').on('click', () => {
                console.log('clicked debugger',);

                enableDebugMode();
            });
        }
        this.menu = new Menu();
        this.expandableInfo = new ExpandableInfo();
        this.parallaxMobile = new ParallaxMobile();

        const leesMeerService = new LeesMeerService();
        this.cookieService = new CookieService();
        this.bezoeklesDetailImagesService = new BezoeklesDetailImagesService();

        if (window.location.pathname.indexOf('/bezoeklessen') !== -1) {
            this.bezoekLessen = new BezoeklessenApp();
            // _singletons.bezoeklessen = this.bezoekLessen;
        }
        const $shareButtons = $('.bezoeklesDetailShareButtons');
        if ($shareButtons.length > 0) {
            $('.bezoeklesDetailButtons__delen').click(() => {
                if ($shareButtons.hasClass('bezoeklesDetailShareButtons--open')) {
                    $shareButtons.removeClass('bezoeklesDetailShareButtons--open');
                } else {
                    $shareButtons.addClass('bezoeklesDetailShareButtons--open');
                }
            });
        }
        const isGamedetailPage = true;
        if (isGamedetailPage) {
            const $gamedetailInstructieContent = $('#gamedetail-instructie-content');
            const $gamedetailVerbergInstructie = $('#gamedetail-verberg-instructie');
            const $gamedetailShowInstructie = $('#gamedetail-show-instructie');

            $gamedetailShowInstructie.click(() => {
                $gamedetailShowInstructie.addClass('hidden');
                $gamedetailInstructieContent.removeClass('hidden');
                $gamedetailVerbergInstructie.removeClass('hidden');
            });
            $gamedetailVerbergInstructie.click(() => {
                $gamedetailShowInstructie.removeClass('hidden');
                $gamedetailInstructieContent.addClass('hidden');
                $gamedetailVerbergInstructie.addClass('hidden');
            });
        }
        $('.blockNaarBoven').click(() => {
            $('html, body').animate({scrollTop: 0}, 'slow');
        });
        $('.homepageHeader__scrollToThemas').on('click', () => {
            $([document.documentElement, document.body]).animate({
                scrollTop: $('.blockThema--1').offset().top
            }, 'slow');
        });

    };
    // initBezoeklessen = ($static:BezoeklessenElements) => {
    //     this.bezoekLessen = new BezoekLessen(this.bezoekLessenPageModel, $static);
    //     if (this.settings.exposeModel) {
    //         console.info('pageModel vanuit de cshtml', this.bezoekLessenPageModel);
    //     }
    // }
}

export default App;
