import { Observable } from 'rxjs';

export const loadGoogleMapsApi$:Observable<string> = Observable.create((observer) => {
	const apiKey = 'AIzaSyDsm32FyK4_bwzQl_VrFwksNur-U288I1A';
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = `//maps.googleapis.com/maps/api/js?v=3.42&key=${apiKey}&libraries=geometry&callback=__googleMapsApiOnLoadCallback`;
	script.setAttribute('async', '');
	script.setAttribute('defer', '');

	const s = document.getElementsByTagName('script')[0];
	s.parentNode.insertBefore(script, s);
	const onLoad = () => {
		observer.next('gmaps api - LOADING SUCCES');
		observer.complete();
	};
	window['__googleMapsApiOnLoadCallback'] = onLoad;
	observer.next('gmaps api - loading...');
});
