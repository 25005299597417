import FilterDescriptionRadio from '../filter-description-radio.model';

export default class FilterCountService {
	getCoronaFilter():FilterDescriptionRadio {
		const filter = new FilterDescriptionRadio();
		filter.slug = 'corona';
		filter.filters.push({urlName: 'neem-contact-op', predicate: (b) => b.coronaStatus === 'Neem contact op'});
		filter.filters.push({urlName: 'is-mogelijk', predicate: (b) => b.coronaStatus === 'Is mogelijk'});
		filter.filters.push({urlName: 'voorlopig-niet', predicate:(b) => b.coronaStatus ===  'Voorlopig niet'});
		// filter.filters.push({urlName: 'corona-alle', filterName: 'Alle'});
		return filter;
	}
	getLokatieFilter():FilterDescriptionRadio {
		const filter = new FilterDescriptionRadio();
		filter.slug = 'lokatie';
		filter.filters.push({urlName: 'in-de-klas', predicate:(b) => b.inSchool});
		filter.filters.push({urlName: 'de-klas-uit', predicate:(b) => !b.inSchool});
		return filter;
	}
	getPrijsFilter():FilterDescriptionRadio {
		const filter = new FilterDescriptionRadio();
		filter.slug = 'prijs';
		filter.filters.push({urlName: 'tot-50euro', predicate: (b) => b.prijs < 50});
		filter.filters.push({urlName: '50eu-tot-100eu', predicate: (b) => b.prijs <= 100 && b.prijs >= 50});
		filter.filters.push({urlName: 'boven-100euro', predicate:(b) => b.prijs > 100});
		return filter;
	}
	getAfstandFilter():FilterDescriptionRadio {
		const filter = new FilterDescriptionRadio();
		filter.slug = 'afstand';
		filter.filters.push({urlName: 'tot-5km', predicate: (b) => b.afstand < 5});
		filter.filters.push({urlName: 'tot-15km', predicate: (b) => b.afstand < 15});
		filter.filters.push({urlName: 'tot-25km', predicate:(b) => b.afstand < 25});
		return filter;
	}
}
