import { BezoeklesModel } from '../bezoekles.model';
import InstellingModel from '../instelling.model';
import * as _ from 'lodash';
import InstellingenApiModel from './api-models/instellingen-api.model';

export default class InstellingenFactory {
	public createInstellingen = (bezoeklessen:BezoeklesModel[], instellingen:InstellingenApiModel[]):InstellingModel[] => {
		return instellingen.map((instellingApiModel) => {
		    return this.createInstelling(bezoeklessen, instellingApiModel);
		})
	}
	public createInstelling = (bezoeklessen, instellingApiModel):InstellingModel => {
		const bezoeklessenBijInstelling = _.filter(bezoeklessen, (les:BezoeklesModel) => {
			return instellingApiModel.titel === les.instelling;
		});

		const instellingModel:InstellingModel = {
			...instellingApiModel,
			bezoeklessen: bezoeklessenBijInstelling,
			latLng: new google.maps.LatLng(instellingApiModel.lokatie.Latitude, instellingApiModel.lokatie.Longitude)
		};
		return instellingModel;
	}
}
