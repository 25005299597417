import {ClusterIconStyle} from '@googlemaps/markerclustererplus';

const clusterStyle:ClusterIconStyle = 		{
		textColor: 'white',
		url: '/content/images/icons/bezoeklessen-kaart/m1_small.png',
		height: 50,
		width: 50,
		textSize: 14,
		fontWeight: 'normal',
		fontFamily: '"Futura PT W01 Medium", Arial, "Helvetica Neue", Helvetica, sans-serif',
		anchorText: [17,22]
	};
export default class KaartGmapsStyles {

	public static clusterStyles:ClusterIconStyle[] = [ clusterStyle, clusterStyle, clusterStyle	];
	public static mapStyling:google.maps.MapTypeStyle[] = [{
		featureType: 'poi',
		elementType: 'labels',
		stylers: [
			{visibility: 'off'}
		]
	}, {
		featureType: 'road.highway',
		elementType: 'labels',
		stylers: [
			{visibility: 'on'}
		]
	}, {
		featureType: 'road.arterial',
		elementType: 'labels',
		stylers: [
			{visibility: 'on'}
		]
	}, {
		featureType: 'transit.station.bus',
		elementType: 'labels',
		stylers: [
			{visibility: 'off'}
		]
	}
	];
}
