import * as $ from 'jquery';


class Menu  {
  private $button:JQuery = $('#menu__button');
  private $items:JQuery = $('#menu__items');
  private $menu:JQuery = $('#menu');

  /** Zijn de menu items open? */
  private isOpen:boolean = false;
  /** Is de menu button met RidT icon zichtbaar? */
  private isMenuVisible:boolean = true;
  /** De scrollTop voor de huidige scroll event. */
  lastScrollTop:number = 0;

  private menuHeight:number = 60;
    /** Gevoeligheid van de scrollDown menu show */
    private scrollDownSensitivity:number = 80;
    /** Gevoeligheid van de scrollUp menu show */
    private scrollUpSensitivity:number = 80;
    private scrollDownStart:number = 0;
    private scrollUpStart:number = 0;
    /** De snelheid waarmee menu items worden weergegeven. */
    private menuItemsShowSpeed:number = 500;
  constructor() {
      this.assignEvents();
  }

  private assignEvents = ():void => {
      this.$button.click(() => this.isOpen ? this.closeMenuItems() : this.openMenuItems());
      $(window).scroll(this.onScroll);
  };
  /** Opent de menu items */
  public openMenuItems = ():void => {
      this.$items.addClass('menu__items--isOpen');
      this.$button.addClass('menu__button--isOpen');
      this.isOpen = true;
  };
  /** Verbergt de menuItems en al dan niet verbergt de menu balk */
  public closeMenuItems = ():void => {
      this.$items.removeClass('menu__items--isOpen');
      this.$button.removeClass('menu__button--isOpen');
      // Hide menu after the items are hidden
      setTimeout(() => {
          if ($(window).scrollTop() > this.menuHeight) this.hideMenu();
      }, this.menuItemsShowSpeed);
      this.isOpen = false;
  };
  public hideMenu = ():void => {
      this.$menu.addClass('menu--isHidden');
      this.isMenuVisible = false;
  };
  public showMenu = ():void =>{
      this.$menu.removeClass('menu--isHidden');
      this.isMenuVisible = true;
  };
  private onScroll = (e:any):void => {
      const currentScroll = $(window).scrollTop();

      if (currentScroll === 0) {
          this.showMenu();
      } else if (currentScroll > this.lastScrollTop) {
          this.onScrollDown();
      } else if (currentScroll < this.lastScrollTop){
          this.onScrollUp();
      } else {
          console.error(`SCROLL EVENT FOR MENU: Unkown what action to take with currentScroll: ${currentScroll} and lastScroll: ${this.lastScrollTop}`);
      }
      this.lastScrollTop = currentScroll;
  };

  private onScrollDown = ():void => {
      // Pas het menu verbergen als je meer dan 80px (de hoogte van menu) naar beneden bent gescrolled
      const currentScroll = $(window).scrollTop();
      // reset scroll up logic
      this.scrollUpStart = 0;

      if (this.scrollDownStart === 0) {
          this.scrollDownStart = currentScroll;
      }
      const totalDistanceOfScrollingDown = currentScroll - this.scrollDownStart;

      if (currentScroll < this.menuHeight) return;
      if (this.isMenuVisible && !this.isOpen && totalDistanceOfScrollingDown > this.scrollDownSensitivity) {
          this.hideMenu();
      }
  };

  private onScrollUp = ():void => {
      const currentScroll = $(window).scrollTop();
      this.scrollDownStart = 0;

      // Als je al bijna boven bent met scrollen, wacht dan niet om menu te openen.
      if (currentScroll < this.menuHeight) {
          this.showMenu();
      }
      if (this.scrollUpStart === 0) {
          this.scrollUpStart = currentScroll;
      }
      const totalDistanceOfScrollingUp = this.scrollUpStart - currentScroll;

      if (!this.isMenuVisible && !this.isOpen && totalDistanceOfScrollingUp > this.scrollUpSensitivity ) {
          this.showMenu();
      }
  }
}

export default Menu;