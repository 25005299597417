import _components from '../_global/bezoeklessen.components';
import FilterStatusModel from '../filter/filter-status/filter-status.model';
import _events from '../_global/bezoeklessen.events';
import _singletons from '../_global/bezoeklessen.singletons';

export default class TutorialSuggestionComponent {
	public $tutorialSuggestionContainer = $('.tutorialSuggestion');
	public $overslaanKnop = $('.tutorialSuggestion__overslaan');
	public $startKnop = $('.tutorialSuggestion__start');
	public $menuTutorialKnop = $('.bezoeklessenMenu__button--tutorial');

	private isActive = false;

	constructor() {
		this.setEvents();
	}

	public render() {
		console.log(_singletons.cookieService.getTutorialCookie())

		if (_singletons.cookieService.getTutorialCookie() !== 'completed') {
			this.animateOpen();
		}
	}

	private setEvents() {
		this.$startKnop.on('click', () => {
			this.animateClose();
			const filters = new FilterStatusModel();
			_events.filtersChange$.next(filters);

			this.$menuTutorialKnop.addClass('bezoeklessenMenu__button--active');

			_components.tutorial.start();
			_singletons.cookieService.setTutorialCookie('completed');
		});
		this.$overslaanKnop.on('click', () => {
			this.animateClose();
			_singletons.cookieService.setTutorialCookie('completed');
		});
		this.$menuTutorialKnop.on('click', () => {
		    if (!this.isActive) {
		    	this.animateOpen()
			} else {
		    	this.animateClose()
			}
		})
	}

	animateOpen() {
		this.isActive = true;

		const node = document.querySelector('.tutorialSuggestion');
		node.classList.add('animated', 'bounceInUp');
		this.$tutorialSuggestionContainer.removeClass('tutorialSuggestion--hidden');

		function handleAnimationEnd() {
			node.classList.remove('animated', 'bounceInUp');
			node.removeEventListener('animationend', handleAnimationEnd);

		}

		node.addEventListener('animationend', handleAnimationEnd);
	}

	private animateClose() {
		this.isActive = false;
		const node = document.querySelector('.tutorialSuggestion');
		node.classList.add('animated', 'bounceOutDown');
		setTimeout(() => {
			// animation's default speed is 1s, so after 900ms it should be safe to hide
			this.$tutorialSuggestionContainer.addClass('tutorialSuggestion--hidden');
		}, 900);

		function handleAnimationEnd() {
			node.classList.remove('animated', 'bounceOutDown');
			node.removeEventListener('animationend', handleAnimationEnd);

		}

		node.addEventListener('animationend', handleAnimationEnd);
	}
}
