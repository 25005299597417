import { BezoeklesModel } from '../../bezoekles.model';
import _state from '../../_global/bezoeklessen.state';
import _components from '../../_global/bezoeklessen.components';
import * as _ from 'lodash';

export default class OverlayComponent {
	activeBezoekles:null | BezoeklesModel = null;

	public render(les?:BezoeklesModel) {
		console.log('Rendering les: ', les);

		if (les) {
			this.activeBezoekles = les;
		} else if (this.activeBezoekles && !les) {
			les = _.find(_state.filteredBezoeklessen, (b) => this.activeBezoekles.url === b.url);
		}
		if (!les) {
			console.error('Onbekende les om weer te geven: ', les, this.activeBezoekles);
			return;
		} else {
			this.activeBezoekles = les;
		}
		if (!_state.isMobile()) {
			_components.overlayDesktop.render(les);
		} else {
			_components.overlayMobile.render(les);
		}
	}
	public close() {
		this.activeBezoekles = null;

		if (!_state.isMobile()) {
			_components.overlayDesktop.close();
		} else {
			_components.overlayMobile.close();
		}
	}
}
