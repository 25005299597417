import KaartSettings from './kaart.settings';
import InstellingModel from '../instelling.model';
import _state from '../_global/bezoeklessen.state';
import _components from '../_global/bezoeklessen.components';
import { BezoeklesModel } from '../bezoekles.model';
import _singletons from '../_global/bezoeklessen.singletons';
import * as _ from 'lodash';

export default class KaartComponent {
	isInitialLoad = true;
	private hiddenInstellingMarker:google.maps.Marker;
	private tempBezoeklesMarker:google.maps.Marker;
	private tempHighlightMarker:google.maps.Marker;
	public $wrapper = $(`#bezoeklessenKaart__wrapper`);

	public initKaartView() {
		const $body = $('body');
		$body.removeClass('p-bezoeklessen--lijst');
		$body.addClass('p-bezoeklessen--kaart');
		_components.kaart.$wrapper.addClass('hidden');
		this.$wrapper.removeClass('hidden');
	}

	public render = (instellingen:InstellingModel[]) => {
		console.log('Rendering Kaart Component', );

		_components.markers.render(instellingen);
		if (_state.currentFilters.instelling) {
			const instellingToOpen = _.find(instellingen, (instelling:InstellingModel) => {
				return instelling.titel === _state.currentFilters.instelling;
			});
			if (instellingToOpen) {
				if (this.isInitialLoad) {
					setTimeout(() => {
						_state.kaart.activeInstellingMarker$.next(_components.markers.activeInstellingenMarkers[0]);
					}, 500);
				} else {
					_state.kaart.activeInstellingMarker$.next(_components.markers.activeInstellingenMarkers[0]);
				}
			}
		}


	};
	public fitAllMarkers() {
		const bounds = new google.maps.LatLngBounds();
		for (let marker of _components.markers.activeInstellingenMarkers) {
			bounds.extend(marker.getPosition());
		}
		_state.kaart.map.fitBounds(bounds);
	}
	public initMap = () => {
		const mapElement = document.getElementById('bezoeklessenKaart');
		_state.kaart.map = new google.maps.Map(mapElement, KaartSettings.mapSettings());
		google.maps.event.addListenerOnce(_state.kaart.map, 'idle', this.onFirstIdle);
	};

	private onFirstIdle = () => {
		this.fitAllMarkers()
		_components.legenda.render();
		_components.gelderlandOutline.render();
		_components.menuKaart.render();
		_state.kaart.activeInstellingMarker$.subscribe(this.handleActiveInstellingMarkerChange);
		_state.kaart.activeBezoeklesOverlay$.subscribe(this.setBezoeklesOverlay);
		_state.kaart.map.addListener('click', () => {
			_state.kaart.activeBezoeklesOverlay$.next(null);
		});
		_state.kaart.map.addListener('zoom_changed', () => {
			_state.kaart.activeBezoeklesOverlay$.next(null);
		});
		_state.kaart.map.addListener('idle', () => {

		});

		// const control = $('<div class="resetZoomButton"><i class="fas fa-search-minus fa-3x"</div>')
		// 	.on('click', this.resetZoom);
		// _state.kaart.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(control.get(0));
		$('.resetZoomButton').on('click', () => {
		    this.resetZoom();
		});
		this.isInitialLoad = false;

	};
	public resetZoom = () => {
		if ($(window).width() < 500) {
			_state.kaart.map.setZoom(8);
		} else {
			_state.kaart.map.setZoom(KaartSettings.defaultZoom);
		}
		_state.kaart.map.setCenter(KaartSettings.defaultCenter);
	};
	private handleActiveInstellingMarkerChange = (instellingMarker:google.maps.Marker | null) => {
		if (this.hiddenInstellingMarker && this.tempBezoeklesMarker) {
			this.resetInstellingMarkerMetSingleBezoekles();
		}
		if (!instellingMarker) {
			_components.overlay.close();
		} else {
			this.focusInstelling(instellingMarker);
		}
	};

	private focusInstelling = (instellingMarker:google.maps.Marker) => {
		_state.kaart.map.panTo(instellingMarker.getPosition());
		if (_state.kaart.map.getZoom() < 15) _state.kaart.map.setZoom(15);

		google.maps.event.addListenerOnce(_state.kaart.map, 'idle', () => {
			const instelling:InstellingModel = instellingMarker.get('instelling');
			if (instelling.bezoeklessen.length === 1) {
				this.focusInstellingWithSingleBezoekles(instellingMarker, instelling);
			} else if (instelling.bezoeklessen.length > 1) {
				_components.overlay.close();
				_components.oms.render(instellingMarker);
			}
		});
	};

	private focusInstellingWithSingleBezoekles = (instellingMarker:google.maps.Marker, instelling:InstellingModel) => {
		_components.oms.removeActiveSpider();
		const bezoekles = instelling.bezoeklessen[0];

		_state.kaart.activeBezoeklesOverlay$.next(bezoekles);

		this.hiddenInstellingMarker = instellingMarker;
		instellingMarker.setMap(null);
		this.tempBezoeklesMarker = _singletons.markerFactory.createBezoeklesMarker(bezoekles);
		this.tempBezoeklesMarker.setMap(_state.kaart.map);
		this.tempHighlightMarker = _singletons.markerFactory.createHighlightMarker(this.tempBezoeklesMarker);
		this.tempHighlightMarker.setMap(_state.kaart.map);

		_state.kaart.map.panTo(this.tempBezoeklesMarker.getPosition());
	};
	private setBezoeklesOverlay = (bezoekles:BezoeklesModel | null) => {
		if (!bezoekles) {
			if (this.hiddenInstellingMarker && this.tempBezoeklesMarker) {
				this.resetInstellingMarkerMetSingleBezoekles();
			}
			_components.overlay.close();
			return;
		}

		_components.overlay.render(bezoekles);
	};

	private resetInstellingMarkerMetSingleBezoekles = () => {
		this.tempBezoeklesMarker.setMap(null);
		this.tempBezoeklesMarker = null;
		this.tempHighlightMarker.setMap(null);
		this.tempHighlightMarker = null;
		this.hiddenInstellingMarker.setMap(_state.kaart.map);
		this.hiddenInstellingMarker = null;
	};
}
