import _events from '../_global/bezoeklessen.events';
import { BehaviorSubject } from 'rxjs';

export default class MenuKaartComponent {
	private $postcodeButton:JQuery = $('.bezoeklessenMenu__button--postcode');
	private $postcodeSearch:JQuery = $('.bezoeklessenMenu__postcodeSearch');
	private $postcodeInput:JQuery = $('.bezoeklessenMenu__postcodeInput--kaart');
	private $postcodeSubmit:JQuery = $('.bezoeklessenMenu__postcodeSubmit--kaart');
	private $infoButton:JQuery = $('.bezoeklessenMenu__button--info');
	private $filtersButton:JQuery = $('.bezoeklessenMenu__button--filters');
	private $lijstButton:JQuery = $('.bezoeklessenMenu__button--lijst');

	public togglePostcodeInput$ = new BehaviorSubject<boolean>(false);
	private isPostcodeInputActive = false;

	public render = () => {
		_events.kaartToggleLegenda$.subscribe((toonLegenda) => {
			if (toonLegenda) {
				this.$infoButton.addClass('bezoeklessenMenu__button--active');
			} else {
				this.$infoButton.removeClass('bezoeklessenMenu__button--active');
			}
		});
		this.$infoButton.on('click', () => {
			const disableInfo = !this.$infoButton.hasClass('bezoeklessenMenu__button--active');
			_events.kaartToggleLegenda$.next(disableInfo);
		});

		this.$postcodeButton.on('click', () => this.togglePostcodeInput$.next(!this.isPostcodeInputActive));
		this.togglePostcodeInput$.subscribe(this.togglePostcodeInput);
		this.$postcodeSubmit.on('click', this.setNewPostcode);
		this.$postcodeInput.on('keypress', async (e) => {
			if (e.key === 'Enter') {
				await this.setNewPostcode();
			}
		});

		this.$lijstButton.on('click', () => {
			_events.gotoLijstView$.next(true);
		})
	};
	setNewPostcode = async () => {
		// todo - Must work via the FilterStatus oid observable, because also used in menu of lijst
		const postcode = this.$postcodeInput.val() as string;
		const sanitizedPostcode = postcode.replace(' ', '').toUpperCase();

		this.togglePostcodeInput$.next(false);
		this.$postcodeButton.addClass('bezoeklessenMenu__button--active');

		_events.postcodeChange$.next(sanitizedPostcode);
	};
	togglePostcodeInput = (openPostcodeInput) => {
		this.isPostcodeInputActive = openPostcodeInput;
		if (openPostcodeInput) {
			this.$postcodeSearch.addClass('bezoeklessenMenu__postcodeSearch--isOpen');
			this.$postcodeSearch.addClass('bezoeklessenMenu__postcodeSearch--noBorderRadius');
		} else {
			this.$postcodeSearch.removeClass('bezoeklessenMenu__postcodeSearch--isOpen');
			// breng border radius weer terug 0.05s voordat menu weer dicht is, dus .4s - .05s = 350ms later
			setTimeout(() => {
				this.$postcodeSearch.removeClass('bezoeklessenMenu__postcodeSearch--noBorderRadius');
			}, 350);
		}
	};

	renderFilterCount(filterCount:number) {
		let filterButtonHtml = '';
		if (filterCount === 0) {
			filterButtonHtml = '0 filters';
		}
		if (filterCount === 1) {
			filterButtonHtml = '<span class="bezoeklessenMenu__button--filters--getal">1</span> filter';
		} else if (filterCount > 1) {
			filterButtonHtml = '<span class="bezoeklessenMenu__button--filters--getal">' + filterCount + '</span> filters';
		}

		this.$filtersButton.html(filterButtonHtml);
	}
}
