import InstellingModel from '../../instelling.model';
import _state from '../../_global/bezoeklessen.state';
import _singletons from '../../_global/bezoeklessen.singletons';
import KaartSettings from '../kaart.settings';
import MarkerClusterer from '@googlemaps/markerclustererplus';

export default class MarkersComponent {
	activeInstellingenMarkers:google.maps.Marker[] = [];
	activeMarkerClusterer:MarkerClusterer;
	activeLokatieMarker:google.maps.Marker;

	render = (instellingen:InstellingModel[]) => {
		if (this.activeMarkerClusterer) {
			this.activeMarkerClusterer.clearMarkers();
		}
		const instellingenMarkers = instellingen.map(_singletons.markerFactory.createInstellingMarker);
		this.activeInstellingenMarkers = instellingenMarkers;
		this.activeMarkerClusterer = new MarkerClusterer(_state.kaart.map, instellingenMarkers, KaartSettings.markerClusterer);
	};

	renderLokatieMarker = (lokatie:google.maps.LatLng) => {
		if (this.activeLokatieMarker) this.activeLokatieMarker.setMap(null);
		this.activeLokatieMarker = _singletons.markerFactory.createLokatieMarker(lokatie);
		this.activeLokatieMarker.setMap(_state.kaart.map);
	};
}
