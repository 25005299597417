class LeesMeerService {

  $leesMeerButton:JQuery = $('.template_themepart__collapsible-button');

  constructor() {
      this.setEvents();
  }

  leesMeerButtonClick = (e:any):void => {
      const $currentButton = $(e.currentTarget);
      const $wrapper = $currentButton.parent();
      const $targetText = $(e.currentTarget).parent().parent();
      const isCollapsed = $targetText.hasClass('themepart-collapsed');

      if (isCollapsed) {
          $targetText.removeClass('themepart-collapsed');
          $currentButton.removeClass('themepart-collapsed');
          $currentButton.html('minder <i class="fas fa-caret-up" aria-hidden="true"></i>');
          $wrapper.css('height', 0);
      } else {
          $targetText.addClass('themepart-collapsed');
          $currentButton.addClass('themepart-collapsed');
          $currentButton.html('lees meer <i class="fas fa-caret-down" aria-hidden="true"></i>');
          $wrapper.css('height', '75px');

      }
  }

  setEvents(): void {
      this.$leesMeerButton.click(this.leesMeerButtonClick);
  }
}

export default LeesMeerService;
