import GMapsLocationModel from '../../vendor/GMapsLocationModel';

export default class BezoeklesApiModel {
	public id:number | string;
	public titel:string;
	public url:string;
	public urlTitel:string;
	public beschrijving:string;
	public korteBeschrijving:string;
	public prijsTekst:string;
	public prijs:number;
	public imageUrl:string;
	public imageThumbnailUrl:string;
	public soortenAanbod:string[];
	public tijdvakken:string[];
	public onderwerpen:string[];
	public inSchool:boolean;
	public lokatie:GMapsLocationModel;
	// Data om in andere source op te zoeken
	public instellingId:number;
	public themaId:number;
	public emailRegistratie:string;
	public urlRegistratie:string;
	public coronaStatus:string;
	public coronaBericht:string;
}


