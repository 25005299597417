import { BezoeklesModel } from '../bezoekles.model';

export default class FilterDescriptionRadio {
	/** This is used in the classname of the filter */
	public slug:string;
	/** The filter options, where we have the urlname (used to identify in element under data[urlnaam]
	 * & predicate which is the condition under which to count the bezoekles as filtered.
	 * NOTE: Both values must be unique*/
	public filters:FilterDescriptionItem[] = [];
}

export class FilterDescriptionItem {
	urlName:string;
	predicate:(bezoekles:BezoeklesModel) => boolean;
}
