import { BezoeklesModel } from '../bezoekles.model';
import _singletons from '../_global/bezoeklessen.singletons';
import _components from '../_global/bezoeklessen.components';

export default class BezoeklessenFactory {
	public async berekenRechteLijnAfstandBezoeklessen(postcodeLatLng:google.maps.LatLng, bezoeklessen:BezoeklesModel[]) {
		return bezoeklessen.map((bezoekles) => {
			const afstandInMeters = _singletons.afstandService.berekenRechteLijnAfstand(window['google'], postcodeLatLng, bezoekles.lokatie);
			bezoekles.afstand = Math.round(afstandInMeters * 10 / 1000) / 10;
			bezoekles.lijstHtml = _components.lijstItem.renderHtml(bezoekles);
			return bezoekles;
		});
	}
}
