export default class ParallaxMobile {
    $parallax:JQuery;
    $content:JQuery;
    $tooltips:JQuery;
    $overlays:JQuery;

    constructor() {
        this.$parallax = $('#parallaxMobile');
        this.$content = this.$parallax.find('.parallaxMobile__content');
        this.$tooltips = $('.parallaxMobile__tooltip');
        this.$overlays = $('.parallaxMobile__overlay');

        this.setEvents();
    }
    onStartMomentumScroll = () => {
        this.isMomentumScrolling = true;
        this.$tooltips.addClass('parallaxMobile__tooltip--visible');
        this.$overlays.addClass('parallaxMobile__overlay--hidden');
        // $('.shared_singlescreen__overlay').css('opacity', '1');
        clearInterval(this.endMomentumTimeout);

    };
    endMomentumTimeout;
    onEndMomentumScroll = () => {
        this.isMomentumScrolling = false;
        clearInterval(this.endMomentumTimeout);
        this.endMomentumTimeout = setTimeout(() => {
            this.$tooltips.removeClass('parallaxMobile__tooltip--visible');
            this.$overlays.removeClass('parallaxMobile__overlay--hidden');
        }, 200);
    };
    getScrollPercentage = ():number => {
        const maxScrollDistance = 3000 - $(window).width();
        return this.$parallax.scrollLeft() / maxScrollDistance;
    };
    getBackgroundOffset = (percentage:number):string => {
        const maxScrollDistance = 2000 - $(window).width();

        return '-' + Math.floor(percentage*maxScrollDistance)  + 'px 0px';
    };
    isMomentumScrolling:boolean;
    readonly momentumCheckInterval:number = 100;
    momentumTimeout;
    setEvents = ():void => {
        this.$parallax.on('scroll', () => {
            if (!this.isMomentumScrolling) this.onStartMomentumScroll();

            const newBackgroundPosition = this.getBackgroundOffset(this.getScrollPercentage());
            this.$parallax.css('background-position', newBackgroundPosition);

            clearInterval(this.momentumTimeout);
            this.momentumTimeout = setTimeout(() => {
                this.onEndMomentumScroll();
            }, this.momentumCheckInterval)
        });

    }
}
