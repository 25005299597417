import MarkerFactory from '../kaart/markers/marker.factory';
import InstellingenFactory from '../data/instellingen.factory';
import FilterService from '../filter/filter.service';
import BezoeklessenFactory from '../data/bezoeklessen.factory';
import AfstandService from '../postcode/afstand.service';
import LinkAnalyzerService from '../link/link-analyzer.service';
import LinkBuilderService from '../link/link-builder.service';
import SearchService from '../filter/search/search.service';
import SorteerService from '../lijst/sorteer.service';
import FilterShareService from '../filter/filter-share/filter-share.service';
import FilterCountService from '../filter/filter-counts/filter-count.service';
import CookieService from '../../../Services/CookieService';
import BezoeklesDal from '../data/bezoekles.dal';

class BezoeklessenSingletons {
	bezoeklesDal = new BezoeklesDal();

	linkAnalyzer = new LinkAnalyzerService();
	linkBuilder = new LinkBuilderService();
	bezoeklessenFactory = new BezoeklessenFactory();
	instellingenFactory = new InstellingenFactory();
	markerFactory = new MarkerFactory();
	afstandService = new AfstandService();
	filterService:FilterService;
	searchService = new SearchService();
	sorteerService = new SorteerService();

	filterShareService = new FilterShareService();
	filterCountService = new FilterCountService();

	cookieService = new CookieService();
}

const _singletons = new BezoeklessenSingletons();

_singletons.filterService = new FilterService(_singletons.instellingenFactory);

export default _singletons;

window['_singletons'] = _singletons;
