import { OverlappingMarkerSpiderfier } from '../../vendor/OverlappingMarkerSpiderfier';
import _state from '../../_global/bezoeklessen.state';
import KaartSettings from '../kaart.settings';
import InstellingModel from '../../instelling.model';
import _singletons from '../../_global/bezoeklessen.singletons';
import { BezoeklesModel } from '../../bezoekles.model';

export default class OmsComponent {
	activeOms:OverlappingMarkerSpiderfier | null = null;
	tempHighlightMarker:google.maps.Marker;

	public render = (instellingMarker:google.maps.Marker) => {
		if (this.activeOms) this.removeActiveSpider();

		this.activeOms = new OverlappingMarkerSpiderfier(_state.kaart.map, KaartSettings.overlappingMarkerSpiderfier);
		const instelling:InstellingModel = instellingMarker.get('instelling');
		const bezoeklesMarkers:google.maps.Marker[] = instelling.bezoeklessen
			.map(_singletons.markerFactory.createBezoeklesMarker);

		bezoeklesMarkers.forEach((markerToBeSpiderfied) => {
			this.activeOms.addMarker(markerToBeSpiderfied,
				() => this.handleBezoeklesClickInSpider(markerToBeSpiderfied));
		});

		setTimeout(() => {
			google.maps.event.trigger(bezoeklesMarkers[0], 'click');
		}, 100);

		bezoeklesMarkers[0].addListener('spider_format', (markerStatus) => {
			if (markerStatus === 'UNSPIDERFIED') {
				_state.kaart.activeBezoeklesOverlay$.next(null);
				this.removeActiveSpider();
			}
		});
	}
	private handleBezoeklesClickInSpider = (markerToBeSpiderfied:google.maps.Marker) => {
		const bezoekles:BezoeklesModel = markerToBeSpiderfied.get('les');
		if (this.tempHighlightMarker) this.removeHighlightMarker();
		this.tempHighlightMarker = _singletons.markerFactory.createHighlightMarker(markerToBeSpiderfied);
		this.tempHighlightMarker.setMap(_state.kaart.map);
		_state.kaart.activeBezoeklesOverlay$.next(bezoekles);
	}
	public removeActiveSpider() {
		if (this.tempHighlightMarker) this.removeHighlightMarker();
		if (!this.activeOms) return;
		this.activeOms.removeAllMarkers();
		this.activeOms = null;
	}
	private removeHighlightMarker() {
		this.tempHighlightMarker.setMap(null);
		this.tempHighlightMarker = null;
	}
}
