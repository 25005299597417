import { BezoeklesModel } from '../bezoekles.model';

export default class BezoeklesOpsommingenFactory {
	public static createBezoeklesOpsomming(les:BezoeklesModel, horizontaal:boolean = false):string {
		const soortenAanbod = les.soortenAanbod.join(' en ');

		return `
<div class="bezoeklesOpsomming--container ${horizontaal ? 'bezoeklesOpsomming--horizontaal' : ''}">
	${this.createOpsommingCorona2(les)}
    ${this.createOpsommingAfstand(les)}
    <div class="bezoeklesOpsomming bezoeklesOpsomming--money">
        <div class="bezoeklesOpsomming__icon"><i class="fas fa-euro-sign"></i></div>
        <div class="bezoeklesOpsomming__value">${les.prijs === 0 ? 'gratis' : les.prijs + ',-'}</div>
    </div>
    <div class="bezoeklesOpsomming">
        <div class="bezoeklesOpsomming__icon"><i class="fas fa-university" aria-hidden="true"></i></div>
        <div class="bezoeklesOpsomming__value">${soortenAanbod}</div>
    </div>
    ${les.onderwerpen.length > 0 ? this.createOpsommingOnderwerpen(les.onderwerpen) : ''}
    ${les.tijdvakken.length > 0 ? this.createOpsommingTijdvakken(les.tijdvakken) : ''}
</div>
`;
	}
	private static createOpsommingAfstand(les:BezoeklesModel):string {
		const afstandTekst = les.inSchool ? "Specialist in de klas" :
			les.afstand !== 0 ? les.afstand + "km afstand" : 'De klas uit';
		return `
    <div class="bezoeklesOpsomming bezoeklesOpsomming--marker">
        <div class="bezoeklesOpsomming__icon"><i class="fas fa-map-marker-alt"></i></div>
        <div class="bezoeklesOpsomming__value">${afstandTekst}</div>
    </div>
`;
	}
	private static createOpsommingOnderwerpen(onderwerpen:string[]) {
		return `
<div class="bezoeklesOpsomming">
    <div class="bezoeklesOpsomming__icon"><i class="fas fa-book" aria-hidden="true"></i></div>
    <div class="bezoeklesOpsomming__value">${onderwerpen.join(', ')}</div>
</div>
`;
	}
	private static createOpsommingTijdvakken(tijdvakken:string[]) {
		return `
<div class="bezoeklesOpsomming bezoeklesOpsomming--tijdvak">
    <div class="bezoeklesOpsomming__icon bezoeklesOpsomming__icon--tijdvak">
        <img class="bezoeklesOpsomming--tijdvakIcon" src="/Content/images/icons/svg/tijdvak-bezoeklessen.svg"/>
    </div>
    <div class="bezoeklesOpsomming__value">${tijdvakken.join(', ')}</div>
</div>
        `
	}
	private static createOpsommingCorona2(les:BezoeklesModel):string {
		return `
    <div class="bezoeklesOpsomming bezoeklesOpsomming--corona ${les.coronaStatus.toLowerCase().replace(/\s/g, '-')}">
        <div class="bezoeklesOpsomming__icon"><img src="/Content/images/icons/icon-corona.svg" /></div>
        <div class="bezoeklesOpsomming__value">${les.coronaBericht}</div>
    </div>
`;
	}
	private static createOpsommingCorona(les:BezoeklesModel):string {
		return `
			<div class="coronaBericht kan-voorlopig-niet">
				<div class="coronaBericht__text">Hier komt corona bericht</div>
				<div class="coronaBericht__icon"><img src="/Content/images/icons/icon-corona.svg" /></div>
			</div>
`;
	}
}
