import Shepherd from 'shepherd.js';
import _components from '../../_global/bezoeklessen.components';
import { introStepMarkerClick } from './1-intro.step';
import tourButton from '../tutorial-button.object';

const html = `
<p>Vul je postcode in om bezoeklessen in de buurt te vinden.  </p>
<p>Bij de filters kun je een maximale afstand instellen. </p>
`;

const menuAfstandStep:Shepherd.Step.StepOptions = {
	id: 'menu-afstand',
	title: 'Afstand berekenen',
	text: html,
	attachTo: {
		element: '.bezoeklessenMenu.bezoeklessenMenu--kaart',
		on: 'bottom',

	},
	classes: 'bezoeklessenKaart__step menu-afstand',
	buttons: [
		tourButton.stoppen,
		{
			text: 'Vorige',
			action: () => {
				// menu-algemeen
				_components.tutorial.tour.back();
			}
		},
		// {
		// 	text: 'Overslaan',
		// 	action: () => {
		// 		// menu-zoeken
		// 		_components.tutorial.tour.show('menu-zoeken');
		// 		_components.tutorial.isAfstandSkipped = true;
		// 		// _components.tutorial.tour.next();
		// 	}
		// },
		{
			text: 'Volgende',
			action: () => {
				// afstand-postcode
				if (!$('.bezoeklessenMenu__postcodeSearch').hasClass('bezoeklessenMenu__postcodeSearch--isOpen')) {
					$('#bezoeklessenMenu__buttonPostcode').click();
				}
				introStepMarkerClick();
				setTimeout(() => {
					$('div[title*="Dieren bij het Paleis"]').click()
				}, 1000)
				_components.tutorial.tour.next();
			}
		},

	]
}

export default menuAfstandStep;
