import Shepherd from 'shepherd.js';
import _state from '../../_global/bezoeklessen.state';
import _components from '../../_global/bezoeklessen.components';
import { exampleWord, timeBetweenInput, zoekVoorbeeld } from './een-instelling-zoeken.step';
import FilterStatusModel from '../../filter/filter-status/filter-status.model';
import _events from '../../_global/bezoeklessen.events';
import { setFilterStatusFilterStep } from './filter-intro.step';
import tourButton from '../tutorial-button.object';

const html = `
<p>Klik onderaan op <i>Deel filters</i> om je filterselectie te bewaren of te delen.</p>
<p>De huidige filters staan ook in de URL.</p>
`;

const filterDelenStep:Shepherd.Step.StepOptions = {
	id: 'filter-delen',
	title: 'Filterselectie delen',
	text: html,
	attachTo: {
		element: '.filters',
		on: 'right'
	},
	classes: 'bezoeklessenKaart__step filter-delen',
	buttons: [
		tourButton.stoppen,
		{
			text: 'Vorige',
			action: () => {
				// filter-active
				const filterStatus = new FilterStatusModel();
				_events.filtersChange$.next(filterStatus);
				// _components.bezoeklessenComponent.render();
				_components.tutorial.tour.back();
			}
		},
		{
			text: 'Volgende',
			action: () => {
				// menu-lijst
				_components.tutorial.tour.next();
				const filterStatus = new FilterStatusModel();
				_events.filtersChange$.next(filterStatus);

				_events.gotoLijstView$.next(true);

			}
		},

	]
};

export default filterDelenStep;
