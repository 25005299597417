import { Subject } from 'rxjs';
import FilterStatusModel from '../filter/filter-status/filter-status.model';
import { BezoeklesModel } from '../bezoekles.model';
import BezoeklessenDataModel from '../data/bezoeklessen-data.model';
import InstellingModel from '../instelling.model';


class BezoeklessenState {
	data:BezoeklessenDataModel;
	afstandLokatie:google.maps.LatLng;
	afstandPostcode:string;

	filteredBezoeklessen:BezoeklesModel[];
	filteredInstellingen:InstellingModel[];
	currentFilters:FilterStatusModel;

	isMobile = () => $(window).width() < 500;

	kaart = new KaartState();
	sorteerOp:string = 'leerjaar';

	appName = 'bezoeklessen';

	isFirstVisit():boolean {
		return true;
	}
}

class KaartState {
	map:google.maps.Map;
	activeInstellingMarker$ = new Subject<google.maps.Marker | null>();
	activeBezoeklesOverlay$ = new Subject<BezoeklesModel | null>();
}

const _state = new BezoeklessenState();

export default _state;
window['_state'] = _state;
