import { BezoeklesModel } from '../../bezoekles.model';
import _state from '../../_global/bezoeklessen.state';
import BezoeklesOpsommingenFactory from '../../shared/bezoekles-opsommingen.factory';
import _components from '../../_global/bezoeklessen.components';
import _singletons from '../../_global/bezoeklessen.singletons';

export default class OverlayDesktopComponent {
	private $container = $('.overlayDesktop--container');
	private $overlayDesktop = $('.overlayDesktop');
	private $title = $('.overlayDesktop__title');
	private $close = $('.overlayDesktop__close');
	private $number = $('.overlayDesktop__number');
	private $titleText = $('.overlayDesktop__title--text');
	private $subtitle = $('.overlayDesktop__subtitle');
	private $image = $('.overlayDesktop__image');
	private $imageRound = $('.overlayDesktop__image .roundImage');

	private $opsomming = $('.overlayDesktop .bezoeklesOpsomming--container');
	private $beschrijving = $('.overlayDesktop__beschrijving');

	private $buttonDetails = $('.overlayDesktop__button--details');

	private $routeAfstandAuto = $(`.overlayDesktop__routeAfstand--auto`);
	private $routeAfstandFiets = $(`.overlayDesktop__routeAfstand--fiets`);

	constructor() {
		this.$close.on('click', () => {
		    _state.kaart.activeInstellingMarker$.next(null);
		});
	}

	public async render(les:BezoeklesModel) {
		this.clearClasses();

		this.$overlayDesktop.addClass('overlayDesktop--theme' + les.leerJaar);
		this.$title.attr('href', les.url);
		this.$number.html(''+les.leerJaar);
		this.$number.addClass('overlayDesktop__number--theme' + les.leerJaar);
		this.$titleText.html(les.titel);

		this.$subtitle.html(les.instelling);

		this.$image.attr('href', les.url);
		this.$imageRound.css('background-image', `url('${les.imageUrl}')`);

		const opsommingItems = $(BezoeklesOpsommingenFactory.createBezoeklesOpsomming(les)).html();
		this.$opsomming.html(opsommingItems);

		this.$beschrijving.html(les.beschrijving);

		this.$buttonDetails.attr('href', les.url);

		this.$container.addClass('overlayDesktop--open');

		if (les.afstand) {
			_components.route.render(_state.afstandLokatie, les.lokatie, 'overlayDesktop');
			const autoAfstand = await _singletons.afstandService
				.berekenAfstandMetDistanceMatrix(_state.afstandLokatie, les.lokatie, google.maps.TravelMode.DRIVING);
			this.$routeAfstandAuto.html(` (${autoAfstand})`);
			const fietsAfstand = await _singletons.afstandService
				.berekenAfstandMetDistanceMatrix(_state.afstandLokatie, les.lokatie, google.maps.TravelMode.BICYCLING);
			this.$routeAfstandFiets.html(` (${fietsAfstand})`);
		}
	}

	public close = () => {
		this.$container.removeClass('overlayDesktop--open');
		this.clearClasses();
	}

	private clearClasses = () => {
		for (let i = 1; i < 9; i++) {
			this.$overlayDesktop.removeClass('overlayDesktop--theme' + i);
			this.$number.removeClass('overlayDesktop__number--theme' + i);
		}
	}
}
