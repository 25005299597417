import GMapsLocationModel from '../../vendor/GMapsLocationModel';

export default class InstellingenApiModel {
	public id:number | string;
	public titel:string;
	public url:string;
	public urlTitel:string;
	public adres:string;
	public postcode:string;
	public woonplaats:string;
	public telefoon:string;
	public website:string;
	public emailApplicatie:string;
	public urlRegistratieSysteem:string;
	public lokatie:GMapsLocationModel;
}
