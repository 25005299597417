import {Observable} from 'rxjs';
import {BezoeklesDto, CanonDto} from './dto/bezoekles.dto';
import {ajax, AjaxResponse} from 'rxjs/ajax';
import {map, tap} from 'rxjs/operators';
import BezoeklessenDataModel from './bezoeklessen-data.model';
import BezoeklessenPaginaApiModel from './api-models/bezoeklessen-pagina-api.model';
import BezoeklesApiModel from './api-models/bezoekles-api.model';
import GMapsLocationModel from '../vendor/GMapsLocationModel';
import {BezoeklesModel} from '../bezoekles.model';
import _components from '../_global/bezoeklessen.components';
import _singletons from '../_global/bezoeklessen.singletons';
import * as _ from 'lodash';
import ThemaApiModel from './api-models/thema-api.model';
import InstellingenApiModel from './api-models/instellingen-api.model';
import _state from '../_global/bezoeklessen.state';
function truncate(str, n){
    return (str.length > n) ? str.substr(0, n-1) + '&hellip;' : str;
};
export default class BezoeklesDal {
    getAll$():Observable<BezoeklesDto[]> {
        return ajax('/api/v1/bezoeklessen')
            .pipe(
                map((response:AjaxResponse) => {
                    return response.response as BezoeklesDto[];
                }),
                // tap((dtos) => console.log('BezoeklesDal.getAll$() ', dtos))
            );
    }

    getOldModel$():Observable<BezoeklessenDataModel> {
        console.time('BezoeklessenDataModel');
        return this.getAll$().pipe(
            tap((dtos) => console.timeLog('BezoeklessenDataModel', 'Received the dtos: ', dtos)
            ),
            map(dtos => {
                return this.convertToOldModel(dtos);
            }));
    }

    private convertThema(dtos:BezoeklesDto[]):ThemaApiModel[] {
        const uniqueThemas = _.uniqBy(dtos, (dto) => dto.thema.id);
        return uniqueThemas.map((dto) => {
            return {
                ...new ThemaApiModel(),
                id: dto.thema.id,
                titel: dto.thema.titel,
                urlTitel: dto.thema.urlTitel
            };
        });
    }

    private convertInstellingen(dtos:BezoeklesDto[]):InstellingenApiModel[] {
        const uniqueInstellingen = _.uniqBy(dtos, (dto) => dto.instelling.id);

        return uniqueInstellingen.map((dto) => {
            return {
                ...new InstellingenApiModel(),
                id: dto.id,
                titel: dto.instelling.titel,
                urlTitel: dto.instelling.urlTitel,
                woonplaats: dto.instelling.woonplaats,
                lokatie: {Latitude: dto.instelling.latitude, Longitude: dto.instelling.longitude}
            };
        });
    }

    private convertBezoeklessen(dtos:BezoeklesDto[]):BezoeklesApiModel[] {
        return dtos.map((dto) => {
            return {
                ...new BezoeklesApiModel(),
                id: dto.id,
                titel: dto.titel,
                url: dto.url,
                beschrijving: truncate(dto.omschrijving, 500),
                prijsTekst: 'Kosten text',
                prijs: 777,
                imageUrl: dto.hoofdplaatjeUrl,
                soortenAanbod: dto.soortAanbod,
            };
        });
    }

    private convertToPaginaDataModel(dtos:BezoeklesDto[]):BezoeklessenPaginaApiModel {
        const bezoeklessenPaginaData:BezoeklessenPaginaApiModel = new BezoeklessenPaginaApiModel();
        bezoeklessenPaginaData.bezoeklessen = this.convertBezoeklessen(dtos);
        console.timeLog('BezoeklessenDataModel', 'Converted bezoeklessen', bezoeklessenPaginaData.bezoeklessen);
        bezoeklessenPaginaData.instellingen = this.convertInstellingen(dtos);
        console.timeLog('BezoeklessenDataModel', 'Converted instellingen', bezoeklessenPaginaData.instellingen);
        bezoeklessenPaginaData.themas = this.convertThema(dtos);
        console.timeLog('BezoeklessenDataModel', 'Converted themas', bezoeklessenPaginaData.themas);

        return bezoeklessenPaginaData;
    }

    private convertToOldModel(dtos:BezoeklesDto[]):BezoeklessenDataModel {
        const bezoeklessenModel = new BezoeklessenDataModel();
        bezoeklessenModel.apiData = this.convertToPaginaDataModel(dtos);
        // const instellingenIndexedById:any = bezoeklessenPaginaData.instellingen.reduce((acc, curr) => {
        //     acc[curr.id] = curr;
        //     return acc;
        // }, {});
        // bezoeklessenModel.instellingenIndexedById = instellingenIndexedById;
        // const themasIndexedById:any = bezoeklessenPaginaData.themas.reduce((acc, curr) => {
        //     acc[curr.id] = curr;
        //     return acc;
        // }, {});
        // bezoeklessenModel.themasIndexedById = themasIndexedById;
        //
        const bezoeklessenZonderMarker = [];
        const bezoeklessen = [];
        dtos.forEach((dto:BezoeklesDto) => {
            try {
                if (dto.titel === 'Expeditie Parashoot: Megchelen – Landfort') {
                    console.log('Expeditie Parashoot: Megchelen – Landfort: ', dto);

                }
                const instellingTitel = dto.instelling.titel;
                const leerJaar = dto.thema.leerjaar;

                const hasLokatie = dto.locatie.latitude !== 0 || dto.instelling.latitude !== 0;
                const prijsTekst = 'TODO: prijstekst';
                const bezoekles:BezoeklesModel = {
                    titel: dto.titel,
                    url: dto.url,
                    beschrijving: truncate(dto.omschrijving, 500),
                    prijsTekst,
                    imageUrl: dto.hoofdplaatjeThumbnailUrl,
                    soortenAanbod: dto.soortAanbod,
                    prijs: dto.kosten.excursieGastles,
                    themaNaam: dto.thema.titel,
                    themaUrlNaam: dto.thema.urlTitel,
                    tijdvakken: dto.tijdvakken,
                    onderwerpen: dto.onderwerpen,
                    // Tijd om de html lijst items te maken is ~100ms
                    lijstHtml: '',
                    inSchool: dto.locatieType.indexOf('Excursie') === -1,
                    leerJaar: leerJaar,
                    instelling: instellingTitel,
                    afstand: 0,
                    lokatie: new google.maps.LatLng(0, 0),
                    coronaBericht: dto.coronaBericht,
                    coronaStatus: dto.coronaStatus,
                    laatsteCoronaUpdate: undefined,
                    laatsteUpdate: new Date()
                };

                if (hasLokatie) {

                    // const lokatie:GMapsLocationModel = !les.inSchool ? instellingLokatie :
                    //     les.lokatie.Latitude === 0 ? instellingLokatie : les.lokatie;

                    bezoekles.titel = dto.titel;
                    bezoekles.lokatie = new google.maps.LatLng(dto.locatie.latitude, dto.locatie.longitude);

                    // todo afstand berekenen middels api
                    // bezoekles.afstand = locatie ? AfstandService.berekenRechteLijnAfstand(window['google'],
                    // 	locatie,
                    // 	{lng: lokatie.Longitude, lat: lokatie.Latitude}) : 0;

                    bezoekles.afstand = Math.floor(bezoekles.afstand / 1000);
                    bezoekles.url = dto.url; // todo: should be created in C#, though url is not needed since it's baked into the htmlList
                    bezoekles.beschrijving = truncate(dto.omschrijving, 500);
                    bezoekles.prijsTekst = prijsTekst;
                    bezoekles.imageUrl = dto.hoofdplaatjeThumbnailUrl;

                    // todo bezoeklesList components maken
                    bezoekles.lijstHtml = _components.lijstItem.renderHtml(bezoekles);
                    bezoeklessen.push(bezoekles);
                } else {
                    bezoeklessenZonderMarker.push({
                        instelling: bezoekles.instelling,
                        thema: bezoekles.themaNaam
                    });
                }
                return bezoekles;
            } catch (e) {
                console.error('ERROR!!', e);
            }

        });
        bezoeklessenModel.alleBezoeklessen = bezoeklessen;
        bezoeklessenModel.alleBezoeklessenZonderMarker = bezoeklessenZonderMarker;

        if (bezoeklessenZonderMarker.length > 0) console.error('BezoeklessenComponent zonder Marker: ', bezoeklessenZonderMarker);
        bezoeklessenModel.alleInstellingen = bezoeklessenModel.apiData.instellingen.map((instelling) => {
            return _singletons.instellingenFactory.createInstelling(bezoeklessen, instelling);
        });

        return bezoeklessenModel;
    }
}
