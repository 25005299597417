import Shepherd from 'shepherd.js';
import _components from '../../_global/bezoeklessen.components';
import _events from '../../_global/bezoeklessen.events';
import tourButton from '../tutorial-button.object';
import { take } from 'rxjs/operators';

const html = `
<p>Heb je aanvullende vragen? Neem dan contact op <a href="mailto:info@reizenindetijd.nl">info@reizenindetijd.nl</a>.</p>
<p>Druk op <i>Opnieuw</i> om de uitleg nog een keer te volgen of op <i>Sluiten</i> om zelf aan de slag te gaan.</p>
`;

const eindeLijstStep:Shepherd.Step.StepOptions = {
	id: 'einde',
	title: 'Einde',
	text: html,
	attachTo: {
		element: '#bezoeklessen',
		on: 'right'
	},
	classes: 'bezoeklessenKaart__step einde',
	buttons: [
		tourButton.stoppen,
		{
			text: 'Vorige',
			action: () => {
				_components.tutorial.tour.back();
			}
		},
		{
			text: 'Opnieuw',
			action: () => {
				_components.tutorial.tour.complete();
				_events.gotoLijstView$.pipe(take(1)).subscribe((isLijstView) => {
					if (isLijstView) {
						_events.gotoLijstView$.next(false);
					}
				});
				setTimeout(() => {
					_components.tutorial.tour.start();
				}, 1000);
			}
		},
		{
			text: 'Sluiten',
			action: () => {
				_components.tutorial.stop();
			}
		}
	]
};

export default eindeLijstStep;
