import instellingenApi$ from './data/bezoeklessen-api.observable';
import { loadGoogleMapsApi$ } from './data/gmaps-api.observable';
import { forkJoin } from 'rxjs';
import _components from './_global/bezoeklessen.components';
import _state from './_global/bezoeklessen.state';
import BezoeklessenDataModel from './data/bezoeklessen-data.model';
import _singletons from './_global/bezoeklessen.singletons';

export default class BezoeklessenApp {
	constructor() {
	  console.log('Init Bezoeklessen App', );

		const $body = $('body');
		loadGoogleMapsApi$.subscribe((msg) => {
		}, null, () => {
			console.log('loadGoogleMapsApi$ - Completed',);
			_singletons.bezoeklesDal.getOldModel$().subscribe(async (data) => {
				debugger;
				_state.data = data;
				await _components.bezoeklessenComponent.init();
				_components.route.directionsDisplay = new google.maps.DirectionsRenderer({suppressMarkers: true});
			})
			// instellingenApi$.subscribe(async (data:BezoeklessenDataModel) => {
			// 	_state.data = data;
			// 	await _components.bezoeklessenComponent.init();
			// 	_components.route.directionsDisplay = new google.maps.DirectionsRenderer({suppressMarkers: true});
			// });
		});
	}
}

