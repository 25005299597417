import * as _ from 'lodash';
import FilterData from '../filter/filter.data';

import InstellingenApiModel from '../data/api-models/instellingen-api.model';
import FilterStatusModel from '../filter/filter-status/filter-status.model';
import _state from '../_global/bezoeklessen.state';
import _singletons from '../_global/bezoeklessen.singletons';
import _components from '../_global/bezoeklessen.components';


export default class LinkAnalyzerService {

	constructor() {
	}

	/** Analyzes the current link and produces an appStatus from it. */
	public getFilterStatusFromLink = async (instellingen:InstellingenApiModel[] = [], path:string):Promise<FilterStatusModel> => {
		const filterStatus = new FilterStatusModel();

		const rawSettings = path.replace('/bezoeklessen', '');
		if (rawSettings === '') return filterStatus;

		let settings:string[];
		settings = rawSettings.substring(1).split('/');

		let hasAfstandFilter = false;
		const hasPostcode = LinkAnalyzerService.hasPostcode(settings[0]);
		if (hasPostcode) {

			// bezoeklessenState.hasPostcode = true;

			const afstandPostcode = settings[0].replace(' ', '').toUpperCase();
			_state.afstandLokatie = await _singletons.afstandService.geocodePostcode(afstandPostcode);
			_state.afstandPostcode = afstandPostcode;
			_state.data.alleBezoeklessen =
				await _singletons.bezoeklessenFactory.berekenRechteLijnAfstandBezoeklessen(_state.afstandLokatie, _state.data.alleBezoeklessen);

			_components.markers.renderLokatieMarker(_state.afstandLokatie);

			hasAfstandFilter = LinkAnalyzerService.hasAfstandFilter(settings[1]);
			if (hasAfstandFilter) {
				filterStatus.afstand = settings[1];
			}
		}
		const lokatieFilter = _.find(settings, (setting) => setting === 'in-de-klas' || setting === 'de-klas-uit');
		if (lokatieFilter) {
			filterStatus.lokatie = lokatieFilter;
		}

		let hasThemaFilter:boolean = this.hasThemaFilter(path);
		if (hasThemaFilter) {
			filterStatus.themaFilterGroep = this.getThemaFilter(settings);
		}
		let hasTijdvakFilter = this.hasTijdvakFilter(path);
		if (hasTijdvakFilter) {
			filterStatus.tijdvakFilterGroep = this.getTijdvakFilter(settings);
		}
		let hasSoortAanboedFilter = this.hasSoortAanbodFilter(path);
		if (hasSoortAanboedFilter) {
			filterStatus.soortAanbodFilterGroep = this.getSoortAanbodFilter(settings);
		}
		let hasOnderwerpFilter = this.hasOnderwerpFilter(path);
		if (hasOnderwerpFilter) {
			filterStatus.onderwerpFilterGroep = this.getOnderwerpFilter(settings);
		}

		let prijsFilter = _.find(settings, (setting) => setting === 'tot-50euro' || setting === '50eu-tot-100eu' ||
			setting === 'boven-100euro');
		if (prijsFilter) {
			filterStatus.prijs = prijsFilter;
		}

		let coronaFilter = _.find(settings, (setting) => setting === 'is-mogelijk' || setting === 'neem-contact-op' ||
			setting === 'voorlopig-niet');
		if (coronaFilter) {
			filterStatus.corona = coronaFilter;
		}
		let hasInstellingFilter = path.indexOf('instelling-') !== -1;
		if (hasInstellingFilter) {
			filterStatus.instelling = this.getInstellingFilter(settings, instellingen);
		}
		console.log(`Created filterStatus: `, filterStatus)
		return filterStatus;
	};

	public static hasPostcode(potentialPostcode:string) {
		// allows 7815ca 7815CA 7815 CA
		const nlPostcodeCheck = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;

		return nlPostcodeCheck.test(potentialPostcode);
	}

	public static hasAfstandFilter(potentialAfstand:string) {
		const afstandCheck = /tot-[0-9]{1,2}km/;

		return afstandCheck.test(potentialAfstand);
	}

	private hasThemaFilter(path:string):boolean {
		for (const themaFilter of FilterData.themaFilter) {
			if (path.indexOf('thema-' + themaFilter.urlNaam) !== -1) {
				return true;
			}
		}
		return false;
	}

	private getThemaFilter(settings:string[]):string[] {
		let result = [];
		const settingsLength = settings.length;
		for (let i = 0; i < settingsLength; i++) {
			if (this.hasThemaFilter(settings[i])) {
				result.push(settings[i].replace('thema-', ''));
			}
		}

		return result;
	}

	private hasTijdvakFilter(path:string):boolean {
		for (const tijdvakFilter of FilterData.tijdvakFilter) {
			if (path.indexOf('tijdvak-' + tijdvakFilter.urlNaam) !== -1) {
				return true;
			}
		}
		return false;
	}

	private getTijdvakFilter(settings:string[]):string[] {
		let result = [];
		for (let i = 0; i < settings.length; i++) {
			if (this.hasTijdvakFilter(settings[i])) {
				result.push(settings[i].replace('tijdvak-', ''));
			}
		}
		return result;
	}

	private hasSoortAanbodFilter(path:string) {
		for (const soortAanbodFilter of FilterData.soortAanbodFilter) {
			const soortAanbod = soortAanbodFilter.urlNaam;
			if (path.indexOf('soort-' + soortAanbod) !== -1) {
				return true;
			}
		}
		return false;
	}

	private getSoortAanbodFilter(settings:string[]):string[] {
		let result = [];
		const settingsLength = settings.length;
		for (let i = 0; i < settingsLength; i++) {
			if (this.hasSoortAanbodFilter(settings[i])) {
				result.push(settings[i].replace('soort-', ''));
			}
		}

		return result;
	}

	private hasOnderwerpFilter(path:string) {
		for (const onderwerpFilter of FilterData.onderwerpFilter) {
			const onderwerp = onderwerpFilter.urlNaam;
			if (path.indexOf('onderwerp-' + onderwerp) !== -1) {
				return true;
			}
		}
		return false;
	}

	private getOnderwerpFilter(settings:string[]):string[] {
		let result = [];
		const settingsLength = settings.length;
		for (let i = 0; i < settingsLength; i++) {
			if (this.hasOnderwerpFilter(settings[i])) {
				result.push(settings[i].replace('onderwerp-', ''));
			}
		}

		return result;
	}

	public hasPrijsFilter(potentialPrijs:string) {
		const prijsCheck = /tot-[0-9]{1,3}euro/;

		return prijsCheck.test(potentialPrijs);
	}

	private getInstellingFilter(settings:string[], instellingen:InstellingenApiModel[]) {
		let result = '';
		const settingsLength = settings.length;
		for (let i = 0; i < settingsLength; i++) {
			if (settings[i].indexOf('instelling-') !== -1) {
				const instellingUrlTitel = settings[i].replace('instelling-', '');
				const instelling = _.find(instellingen, (instelling:InstellingenApiModel) => {
					return instelling.urlTitel === instellingUrlTitel;
				});
				result = instelling.titel;
			}
		}

		return result;
	}
}
